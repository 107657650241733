import React, { useEffect } from 'react';
import './App.scss';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useLocation } from 'react-router';
import { NetworkManager } from './utils';
import { bindActionCreators } from 'redux';
import { AccountOperations } from './states/Account';
import { connect, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import { Page404 } from './components/Common';
import { QueryParamProvider, transformSearchStringJsonSafe } from 'use-query-params';
import { businessActions } from './states/Business';
import { useChatService } from './services/ChatService';
import { useAccount, useCurrentBusiness, useDevice } from './hooks';
import { BugsnagService } from './services/BugsnagService';
import Text from 'antd/lib/typography/Text';
import AnalyticService from './services/AnalyticService';
import { fetchUser } from './states/Account/account.reducer';
import Splash from './components/Splash';
import { doFetchLocationInfo } from './services/backend-api';

// Containers
const Layout = React.lazy(() => import('./layouts/Layout'));
// Pages
const LoginPage = React.lazy(() => import('./pages/Login/Login'));
const SignUpPage = React.lazy(() => import('./pages/SignUp/SignUp'));
const AboutBusinessPage = React.lazy(() => import('./pages/AboutBusniess/AboutBusniess'));


const PrivateRoute = ({ component: Component, isLoggedIn, ...rest }) => {
  return (
    <Route render={(props) => (isLoggedIn ? <Component {...props} /> : <Redirect to="/login" />)} />
  );
};

const PublicRoute = ({ children, isLoggedIn, ...rest }) => (
  <Route render={(props) => (isLoggedIn ? <Redirect to="/" /> : children)} />
);

const queryStringifyOptions = {
  transformSearchString: transformSearchStringJsonSafe,
};

function App(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isAuthenticate, user, isLoading } = useAccount();
  const { currentBusiness, isBusinessChanged } = useCurrentBusiness();
  const isTokenSet = !!localStorage.getItem('id_token');
  const showLogin = !user && !isTokenSet;
  const showLoader = window.location.pathname !== '/aboutBusiness' &&
    (isTokenSet && !user) || (isTokenSet && !user && isLoading) || (user && !currentBusiness);
  const { init: initChatService } = useChatService();
  const { sendDeviceInfo } = useDevice();
  useEffect(() => {
    dispatch(fetchUser());
    // if (process.env.REACT_APP_ENV === 'production') {
    AnalyticService.init();
    initChatService();
    if(sessionStorage.countrycode === undefined){
    doFetchLocationInfo().then((data) => {
      if(data){
        sessionStorage.setItem('countrycode',data.country)
        localStorage.setItem('countrycode',data.country)

      }
    })
  }
    // }
  }, []);

  /**
   * effects on business change
   */
  useEffect(() => {
    if (isBusinessChanged) {
      BugsnagService.setMetadata(currentBusiness);
      loadBusinessConfig();
    }
  }, [currentBusiness]);

  useEffect(() => {
    if (isAuthenticate) {
      console.log('UserData', user);
      sendDeviceInfo();
      AnalyticService.setUserData(user);
      props.getCurrentBusiness();
      BugsnagService.setUser(user);
    }
  }, [isAuthenticate]);

  const loadBusinessConfig = () => {
    console.log('BusinessConfig');
    dispatch(businessActions.loadCustomFields());
  };
  return (
    <>
      <ToastContainer position="bottom-right" hideProgressBar={true} autoClose={5000} />
      <BrowserRouter>
        <QueryParamProvider ReactRouterRoute={Route} stringifyOptions={queryStringifyOptions}>
          <React.Suspense fallback={''}>
            {showLoader ? (
              <Splash />
            ) : (
              <Switch>
                <Route
                  path="/login"
                  name="Login Page"
                  render={(props) => {
                    return isAuthenticate ? <Redirect to="/" /> : <LoginPage {...props} />;
                  }}
                />
                <Route
                  path="/register"
                  name="Login Page"
                  render={(props) => {
                    return isAuthenticate ? <Redirect to="/" /> : <SignUpPage {...props} />;
                  }}
                />
                <Route
                  path="/reset-password"
                  name="Login Page"
                  render={(props) => {
                    return isAuthenticate ? <Redirect to="/" /> : <LoginPage {...props} />;
                  }}
                />
                <Route
                  path="/aboutBusiness"
                  name="About Your Business"
                  render={(props) => {
                    return  <AboutBusinessPage {...props} />;
                  }}
                />



                <PrivateRoute path="/" isLoggedIn={!!user} component={Layout} />

                <Route path="*" name="Not Found" render={(props) => <Page404 {...props} />} />
              </Switch>
            )}
          </React.Suspense>
        </QueryParamProvider>
      </BrowserRouter>
    </>
  );
}

const mapStateToProps = ({ account, business }) => ({
  business,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCurrentBusiness: businessActions.getCurrentBusiness,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NetworkManager(App));
