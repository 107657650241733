import { message } from 'antd';
import axios from 'axios';
import { ApiConstant } from '../constants';
import { buildUrl } from '../states/state.utils';
import { clearStorage } from '../utils/helper';

axios.defaults.headers.common.Authorization = localStorage.getItem('id_token');
axios.defaults.headers.common['X-Business'] = localStorage.getItem('currentBusinessId');

axios.interceptors.response.use(
  (successRes) => {
    return successRes;
  },
  ({ response }) => {
    // const { data, status } = response;
    // console.log(data);
    if (response?.status === 0) {
      message.warn('Please check if you are connected to Internet');
    } else if (response?.status === 400) {
      if (response?.data?.error) {
        message.error(response?.data.error?.message);
      } else if (response?.data?.errors) {
        message.error(response?.data?.title);
      }
    } else if (response?.status === 500) {
      message.error({
        content: 'Server Error!',
        key: 'status_500',
        duration: 2,
      });
    } else if (response?.status === 503) {
      message.error('Server is down! Please try after sometime');
    } else if (response?.data?.error?.code === 4001) {
      message.error({
        content: response?.data?.error?.message || "You don't have access, Please contact support!",
        key: 'status_4001',
        duration: 2,
      });
    } else if (response?.status == 401) {
      clearStorage();
      if (window.location.pathname !== '/login') window.location = '/login';
    } else if (response?.status === 4010) {
      message.error('Subscription Required to continue.');
    } else {
      message.error(
        response?.data?.error?.message ||
          response?.data?.title ||
          response?.data?.message ||
          'Something went wrong! Try again.'
      );
      console.log(response?.data?.error?.message);
    }
    throw response;
  }
);

export const reportAxios = axios.create({
  baseURL: process.env.REACT_APP_REPORT_ENDPOINT,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
});

/**
 * @deprecated
 * BUSINESS API
 */
export async function doGetTags() {
  const endpoint = ApiConstant.GET_TAGS;
  return (await axios.get(endpoint)).data;
}

export async function doGetGroups() {
  const endpoint = ApiConstant.GET_USER_GROUPS;
  return (await axios.get(endpoint)).data;
}
/** ---------------------------------
 * ========= BUSINESS SERVICE ==========
 * ---------------------------------- */
export async function doScheduleBusinessReminder(payload) {
  const endpoint = ApiConstant.SCHEDULE_BUSINESS_REMINDER;
  const response = (await axios.post(endpoint, payload)).data;
  return response;
}

export async function doBulkDeleteCustomer(customerIds) {
  const endpoint = ApiConstant.DELETE_BUlK_CUSTOMER;
  // console.log('axios = ', axios.defaults);
  const response = (await axios.delete(endpoint, { data: customerIds })).data;
  console.log('paymentPlan response = ', response);
  return response;
}

export async function doBulkDeleteTransactions(txnIds) {
  const endpoint = ApiConstant.DELETE_BULK_CUSTOMER_TXN;
  // console.log('axios = ', axios.defaults);
  const response = (await axios.delete(endpoint, { data: txnIds })).data;
  console.log('bulk txn delete response = ', response);
  return response;
}

export async function doGetPaymentPlans(customerId, filter) {
  const endpoint = buildUrl(ApiConstant.PAYMENT_PLANS, { customerId, filter });
  // console.log('axios = ', axios.defaults);
  const response = (await axios.get(endpoint)).data;
  console.log('paymentPlan response = ', response);
  return response.data;
}

export async function doCreatePaymentPlans(customerId, payload) {
  const endpoint = buildUrl(ApiConstant.PAYMENT_PLANS, {
    customerId,
  });
  const response = (await axios.post(endpoint, payload)).data;
  return response;
}

export async function doUpdatePaymentPlans(customerId, payload) {
  const endpoint = buildUrl(ApiConstant.UPDATE_PAYMENT_PLAN, {
    customerId,
    paymentPlanId: payload?.id,
  });
  const response = (await axios.put(endpoint, payload)).data;
  return response;
}

export async function doDeletePaymentPlans(customerId, paymentPlanId) {
  const endpoint = buildUrl(ApiConstant.UPDATE_PAYMENT_PLAN, {
    customerId,
    paymentPlanId,
  });
  const response = (await axios.delete(endpoint)).data;
  return response;
}

export async function doUpdateAccountReminder(customerId, payload) {
  const endpoint = buildUrl(ApiConstant.SCHEDULE_ACCOUNT_REMINDER, {
    customerId,
  });
  const response = (await axios.post(endpoint, payload)).data;
  return response;
}

export async function doAddBulkTransaction(payload) {
  const endpoint = ApiConstant.ADD_BULK_TXN;
  const response = (await axios.post(endpoint, payload)).data;
  return response;
}

export async function doGetTransactionReport(payload) {
  const endpoint = ApiConstant.GET_TRANSACTION_REPORT;
  const response = (await reportAxios.post(endpoint, payload)).data;
  return response;
}

/** ---------------------------------
 * ========= EMAIL SERVICE ==========
 * ---------------------------------- */
export async function doSaveMailSettings(payload) {
  const endpoint = ApiConstant.SET_MAIL_SETTINGS;
  const response = (await axios.post(endpoint, payload)).data;
  return response;
}

export async function doFetchEmailTemplates() {
  const endpoint = ApiConstant.GET_EMAIL_TEMPLATES;
  return (await axios.get(endpoint)).data;
}

/** ---------------------------------
 * ========= SMS SERVICE ==========
 * ---------------------------------- */
export async function doFetchSmsTemplates() {
  const endpoint = ApiConstant.GET_SMS_TEMPLATES;
  return (await axios.get(endpoint)).data;
}
export async function doSetSmsTemplates(payload) {
  const endpoint = ApiConstant.SET_SMS_TEMPLATES;
  return (await axios.put(endpoint, payload)).data;
}

/** ---------------------------------
 * ========= STAFF SERVICE ==========
 * ---------------------------------- */
export async function doFetchStaff() {
  const endpoint = ApiConstant.GET_STAFF;
  return (await axios.get(endpoint)).data;
}

export async function doAddStaff(payload) {
  const endpoint = ApiConstant.ADD_STAFF;
  return (await axios.post(endpoint, payload)).data;
}

export async function doUpdateStaff(payload) {
  const endpoint = ApiConstant.UPDATE_STAFF;
  return (await axios.put(endpoint, payload)).data;
}

export async function doDeleteStaff(employeeId) {
  const endpoint = buildUrl(ApiConstant.DELETE_STAFF, { employeeId });
  return (await axios.delete(endpoint)).data;
}

/** ---------------------------------
 * ========= STAFF PERMISSION SETS ==========
 * ---------------------------------- */
export async function doFetchPermissionSets() {
  const endpoint = buildUrl(ApiConstant.GET_PERMISSION_SET);
  return (await axios.get(endpoint)).data;
}

export async function doAddPermissionSet(payload) {
  const endpoint = ApiConstant.ADD_PERMISSION_SET;
  return (await axios.post(endpoint, payload)).data;
}

export async function doUpdatePermissionSet(payload) {
  const endpoint = buildUrl(ApiConstant.UPDATE_PERMISSION_SET, { payload });
  return (await axios.put(endpoint, payload)).data;
}

export async function doDeletePermissionSet(payload) {
  const endpoint = buildUrl(ApiConstant.DELETE_PERMISSION_SET, { payload });
  return (await axios.delete(endpoint)).data;
}

/** ---------------------------------
 * ========= TAG SERVICE ==========
 * ---------------------------------- */
export async function doFetchTags(businessId) {
  const endpoint = buildUrl(ApiConstant.GET_TAGS, { businessId });
  return (await axios.get(endpoint)).data;
}

export async function doAddTag(payload) {
  const endpoint = ApiConstant.ADD_TAG;
  return (await axios.post(endpoint, payload)).data;
}

export async function doUpdateTag(payload) {
  const endpoint = buildUrl(ApiConstant.UPDATE_TAG, { tagId: payload?.id });
  return (await axios.put(endpoint, payload)).data;
}

export async function doDeleteTag(tagId) {
  const endpoint = buildUrl(ApiConstant.DELETE_TAG, { tagId });
  return (await axios.delete(endpoint)).data;
}

/** ---------------------------------
 * ========= USER GROUPS SERVICE ==========
 * ---------------------------------- */
export async function doFetchGroups() {
  const endpoint = ApiConstant.GET_USER_GROUPS;
  return (await axios.get(endpoint)).data;
}

export async function doAddGroup(payload) {
  const endpoint = ApiConstant.ADD_USER_GROUP;
  return (await axios.post(endpoint, payload)).data;
}

export async function doUpdateGroup(payload) {
  const endpoint = buildUrl(ApiConstant.UPDATE_USER_GROUP, { id: payload?.id });
  return (await axios.put(endpoint, payload)).data;
}

export async function doDeleteGroup(id) {
  const endpoint = buildUrl(ApiConstant.DELETE_USER_GROUP, { id });
  return (await axios.delete(endpoint)).data;
}

/** ----------------------------------------
 * ========= CUSTOM FIELDS SERVICE ==========
 * ----------------------------------------- */
export async function doFetchCustomFields(entity) {
  const endpoint = ApiConstant.GET_CUSTOM_FIELDS;
  const params = { entity };
  return (await axios.get(endpoint, { params })).data;
}

export async function doAddCustomField(payload) {
  const endpoint = ApiConstant.ADD_CUSTOM_FIELD;
  return (await axios.post(endpoint, payload)).data;
}

export async function doUpdateCustomField(payload) {
  const endpoint = buildUrl(ApiConstant.UPDATE_CUSTOM_FIELD, {
    fieldId: payload?.id,
  });
  return (await axios.put(endpoint, payload)).data;
}

export async function doDeleteCustomField(fieldId) {
  const endpoint = buildUrl(ApiConstant.DELETE_CUSTOM_FIELD, { fieldId });
  return (await axios.delete(endpoint)).data;
}

export async function doFetchCustomerDocuments(customerId) {
  const endpoint = buildUrl(ApiConstant.GET_CUSTOMER_DOCS, { customerId });
  return (await axios.get(endpoint)).data;
}

export async function doFetchLocationInfo(customerId) {
  const endpoint = 'https://ipinfo.io/?token=37fd2217b03d56';
  return (await axios.get(endpoint)).data;
}

export async function doAddBusiness(payload) {
  const endpoint = ApiConstant.UPDATE_BUSINESS;
  return (await axios.put(endpoint, payload)).data;
}

export async function doLogout() {
  const endpoint = ApiConstant.LOGOUT;
  return (await axios.get(endpoint)).data;
}

export async function doCreateBill(payload) {
  const endpoint = ApiConstant.CREATE_BILL;
  return (await axios.post(endpoint, payload)).data;
}

export async function doGetInvoiceBill(customerId, filter) {
  const endpoint = buildUrl(ApiConstant.CREATE_BILL);
  const params = {
    customerId,
    filter,
  };
  const response = (await axios.get(endpoint, { params })).data;
  console.log('bill response = ', response);
  return response.data;
}

export async function doUpdateBillInvoice(payload) {
  const endpoint = buildUrl(ApiConstant.UPDATE_BILL, {
    id: payload?.id,
  });
  const response = (await axios.put(endpoint, payload)).data;
  return response;
}

export async function doGetInvoiceTranscations(payload) {
  const endpoint = ApiConstant.GET_TRANSACTION_INVOICE;
  const params = {
    ...payload,
  };
  const response = (await axios.get(endpoint, { params })).data;
  console.log('bill response = ', response);
  return response.data;
}

export async function doLinkTranscation(id, payload) {
  const endpoint = buildUrl(ApiConstant.LINK_TRANSACTION, {
    id,
  });
  const response = (await axios.put(endpoint, payload)).data;
  return response;
}

export async function doUnLinkTranscation(id, payload) {
  const endpoint = buildUrl(ApiConstant.UNLINK_TRANSCATION, {
    id,
    linkId: payload?.linkId,
    entity: payload?.entity,
  });
  const response = (await axios.delete(endpoint)).data;
  return response;
}

export async function doGetTransactions(customerId) {
  const endpoint = buildUrl(ApiConstant.GET_CUSTOMER_TXNS, {
    customerId,
  });
  const response = (await axios.get(endpoint)).data;
  return response;
}

export async function doGetInvoices(payload) {
  const endpoint = ApiConstant.GET_INVOICES;
  const params = {
    ...payload,
  };
  const response = (await axios.get(endpoint, { params })).data;
  return response.data;
}

export async function doGetAllItems(q) {
  const endpoint = ApiConstant.GETALL_ITEMS;
  const params = {
    q,
  };
  const response = (await axios.get(endpoint, { params })).data;
  console.log('devyani response = ', response);
  return response.data;
}

export async function doAddBulkReminder(payload) {
  const endpoint = ApiConstant.ADD_BULK_REMINDERS;
  const response = (await axios.post(endpoint, payload)).data;
  return response;
}

export async function doGetAllTaxes(q) {
  const endpoint = ApiConstant.GETALL_TAX;
  const response = (await axios.get(endpoint)).data;
  console.log('devyani response = ', response);
  return response.data;
}

export async function doGetAllDiscounts(q) {
  const endpoint = ApiConstant.GET_DISCOUNTS;
  const response = (await axios.get(endpoint)).data;
  console.log('devyani response = ', response);
  return response.data;
}

export async function doCancelInvoice(id) {
  const endpoint = buildUrl(ApiConstant.CANCEL_INVOICE, {
    id,
  });
  const response = (await axios.patch(endpoint)).data;
  return response;
}

export async function doDeleteInvoice(id) {
  const endpoint = buildUrl(ApiConstant.DELETE_INVOICE, {
    id,
  });
  const response = (await axios.delete(endpoint)).data;
  return response;
}

export async function doDownloadInvoice(params) {
  const endpoint = ApiConstant.DOWNLOAD_INVOICE;

  const response = (await reportAxios.get(endpoint, { params, responseType: 'blob' })).data;
  return response;
}

export async function doGetAllPlans(q) {
  const endpoint = ApiConstant.GET_PLANS;
  const params = {
    recurringType: q,
  };
  const response = (await axios.get(endpoint, { params })).data;
  console.log('devyani response = ', response);
  return response.data;
}

export async function doCancelRecurring(customerId, paymentPlanId, params) {
  const endpoint = buildUrl(ApiConstant.CANCEL_RECURRING, {
    customerId,
    paymentPlanId,
    deleteInvoices: params.deleteInvoices,
    deleteTxns: params.deleteTxns,
  });
  console.log('***********', params, params.deleteInvoices);
  const response = (await axios.patch(endpoint)).data;
  return response;
}

export async function doAddPrePayment(customerId, paymentPlanId, payload) {
  const endpoint = buildUrl(ApiConstant.PRE_PAYMENT, {
    customerId,
    paymentPlanId,
  });
  const response = (await axios.post(endpoint, payload)).data;
  return response;
}

export async function doGetEmiSchedules(customerId, paymentPlanId) {
  const endpoint = buildUrl(ApiConstant.EMI_SCHEDULES, {
    customerId,
    paymentPlanId,
  });

  const response = (await axios.get(endpoint)).data;
  return response;
}

export async function doGetUnits() {
  const endpoint = buildUrl(ApiConstant.GET_UNITS);

  const response = (await axios.get(endpoint)).data;
  return response;
}

export async function doGetAllUnitsTypes() {
  const endpoint = buildUrl(ApiConstant.GET_ALL_UNITS_TYPES);

  const response = (await axios.get(endpoint)).data;
  return response;
}

export async function doAddTax(payload) {
  const endpoint = ApiConstant.GETALL_TAX;
  const response = (await axios.post(endpoint, payload)).data;
  return response;
}

export async function doUpdateTax(id, payload) {
  const endpoint = buildUrl(ApiConstant.UPDATE_TAX, {
    id,
  });
  const response = (await axios.put(endpoint, payload)).data;
  return response;
}

export async function doDeleteTax(id) {
  const endpoint = buildUrl(ApiConstant.UPDATE_TAX, {
    id,
  });
  const response = (await axios.delete(endpoint)).data;
  return response;
}

export async function doAddDiscount(payload) {
  const endpoint = ApiConstant.GET_DISCOUNTS;
  const response = (await axios.post(endpoint, payload)).data;
  return response;
}

export async function doUpdateDiscount(id, payload) {
  const endpoint = buildUrl(ApiConstant.UPDATE_DISCOUNT, {
    id,
  });
  const response = (await axios.put(endpoint, payload)).data;
  return response;
}

export async function doDeleteDiscount(id) {
  const endpoint = buildUrl(ApiConstant.UPDATE_DISCOUNT, {
    id,
  });
  const response = (await axios.delete(endpoint)).data;
  return response;
}

export async function doAddCategory(payload) {
  const endpoint = ApiConstant.GET_CATEGORY;
  const response = (await axios.post(endpoint, payload)).data;
  return response;
}

export async function doUpdateCategory(id, payload) {
  const endpoint = buildUrl(ApiConstant.UPDATE_CATEGORY, {
    id,
  });
  const response = (await axios.put(endpoint, payload)).data;
  return response;
}

export async function doDeleteCategory(id) {
  const endpoint = buildUrl(ApiConstant.UPDATE_CATEGORY, {
    id,
  });
  const response = (await axios.delete(endpoint)).data;
  return response;
}

export async function doGetAllCategorys(q) {
  const endpoint = ApiConstant.GET_CATEGORY;
  const response = (await axios.get(endpoint)).data;
  return response.data;
}

export async function doAddModifiers(payload) {
  const endpoint = ApiConstant.GET_MODIFIERS;
  const response = (await axios.post(endpoint, payload)).data;
  return response;
}

export async function doUpdateModifiers(id, payload) {
  const endpoint = buildUrl(ApiConstant.UPDATE_MODIFIERS, {
    id,
  });
  const response = (await axios.put(endpoint, payload)).data;
  return response;
}

export async function doDeleteModifiers(id) {
  const endpoint = buildUrl(ApiConstant.UPDATE_MODIFIERS, {
    id,
  });
  const response = (await axios.delete(endpoint)).data;
  return response;
}

export async function doGetAllModifiers(q) {
  const endpoint = ApiConstant.GET_MODIFIERS;
  const response = (await axios.get(endpoint)).data;
  return response.data;
}

export async function doAddItem(payload) {
  const endpoint = ApiConstant.GETALL_ITEMS;
  const response = (await axios.post(endpoint, payload)).data;
  return response;
}

export async function doUpdateItem(id, payload) {
  const endpoint = buildUrl(ApiConstant.UPDATE_ITEMS, {
    id,
  });
  const response = (await axios.put(endpoint, payload)).data;
  return response;
}

export async function doDeleteItems(id) {
  const endpoint = buildUrl(ApiConstant.UPDATE_ITEMS, {
    id,
  });
  const response = (await axios.delete(endpoint)).data;
  return response;
}

export async function doAddUnitType(payload) {
  const endpoint = ApiConstant.ADD_UNITS_TYPE;
  const response = (await axios.post(endpoint, payload)).data;
  return response;
}

export async function doUpdateUnitType(id, payload) {
  const endpoint = buildUrl(ApiConstant.UPDATE_UNITS_TYPE, {
    id,
  });
  const response = (await axios.put(endpoint, payload)).data;
  return response;
}

export async function doGetInvoiceSummary(customerId) {
  const endpoint = ApiConstant.INVOICE_SUMMARY;
  const params = {
    customerId,
  };
  const response = (await axios.get(endpoint, { params })).data;
  return response.data;
}

export async function doGetReminders(customerId) {
  const endpoint = ApiConstant.GET_REMINDERS;
  const params = {
    entityId: customerId,
    entity: 'customer',
  };
  const response = (await axios.get(endpoint, { params })).data;
  return response.data;
}

export async function doGetPaymentSummary(customerId) {
  const endpoint = ApiConstant.PAYMENT_SUMMARY;
  const params = {
    customerId,
  };
  const response = (await axios.get(endpoint, { params })).data;
  return response.data;
}

export async function doGetAllPaymentPlans(customerId, filter) {
  const endpoint = ApiConstant.GET_ALL_PAYMENTPLANS;
  const params = {
    customerId,
    filter,
  };
  const response = (await axios.get(endpoint, { params })).data;
  return response.data?.data;
}

export async function doGetAllCustomerPagination(nextToken) {
  const endpoint = ApiConstant.GET_ALL_CUSTOMERS_PAGINATION;
  const params = {
    nextToken,
  };
  const response = (await axios.get(endpoint, { params })).data;
  return response;
}

export async function doGetAllBusinessTypes() {
  const endpoint = ApiConstant.GET_BUSINESS_TYPES;

  const response = (await axios.get(endpoint)).data;
  return response.data;
}

/** -----------------------------------------
 * ============ PAYMENT SERVICE =============
 * ----------------------------------------- */
export async function createPlanOrder(payload) {
  const endpoint = ApiConstant.CREATE_PLAN_ORDER;
  return (await axios.post(endpoint, payload)).data;
}

export async function addPlan(payload) {
  const endpoint = ApiConstant.ADD_PLAN;
  return (await axios.post(endpoint, payload)).data;
}

export async function createAddOnOrder(payload) {
  const endpoint = ApiConstant.CREATE_ADDON_ORDER;
  return (await axios.post(endpoint, payload)).data;
}

export async function addAddOn(payload) {
  const endpoint = ApiConstant.ADD_ADDON;
  return (await axios.post(endpoint, payload)).data;
}

export async function applyDiscount(code) {
  const params = {
    code: code,
  };
  const endpoint = ApiConstant.APPLY_DISCOUNT;
  return (await axios.get(endpoint, { params })).data;
}
