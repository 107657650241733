import { all, call, put, takeLatest } from 'redux-saga/effects';
import types from './types';
import { Api } from '../../services';
import { ApiConstant } from '../../constants';
import { buildUrl } from '../state.utils';
import axios from 'axios';
import { reportAxios } from '../../services/backend-api';

function* getCurrentBusiness(action) {
  const currentBusinessId = localStorage.getItem('currentBusinessId');
  const url = yield call(buildUrl, ApiConstant.GET_BUSINESS_PROFILE, {
    businessId: currentBusinessId,
  });
  yield put({ type: types.GET_BUSINESS_IN_PROGRESS });
  try {
    const response = yield call(Api.get, url, null);
    localStorage.setItem('currentBusinessId', response.id);
    axios.defaults.headers.common['X-Business'] = response.id;
    reportAxios.defaults.headers.common['X-Business'] = response.id;
    yield put({
      type: types.GET_BUSINESS_SUCCESS,
      payload: response,
    });
    yield call(getBusinessTags);
  } catch (error) {
    yield put({
      type: types.GET_BUSINESS_FAILURE,
      error: error,
    });
  }
}

function* getBusinessList(action) {
  yield put({ type: types.GET_ALL_BUSINESS_IN_PROGRESS });
  try {
    const response = yield call(Api.get, ApiConstant.GET_ALL_BUSINESSES);
    yield put({
      type: types.GET_ALL_BUSINESS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: types.GET_ALL_BUSINESS_FAILURE,
      error: error,
    });
  }
}

function* getBusinessTags() {
  const currentBusinessId = localStorage.getItem('currentBusinessId');
  const url = yield call(buildUrl, ApiConstant.GET_TAGS, {
    businessId: currentBusinessId,
  });
  yield put({ type: types.GET_TAGS_IN_PROGRESS });
  try {
    const response = yield call(Api.get, url);
    yield put({
      type: types.GET_TAGS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: types.GET_TAGS_FAILURE,
      error: error,
    });
  }
}

function* getBusinessCustomFields() {
  const url = ApiConstant.GET_CUSTOM_FIELDS;
  try {
    const response = yield call(Api.get, url);
    yield put({
      type: types.GET_CUSTOM_FIELDS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {}
}

function* addBusiness(payload) {
  const url = ApiConstant.ADD_BUSINESS;
  try {
    const response = yield call(Api.post, url,payload);
    yield put({
      type: types.CREATE_BUSINESS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {}
}

export function* watchBusiness() {
  yield all([
    takeLatest(types.GET_BUSINESS_REQUEST, getCurrentBusiness),
    takeLatest(types.GET_ALL_BUSINESS_REQUEST, getBusinessList),
    takeLatest(types.GET_TAGS_REQUEST, getBusinessTags),
    takeLatest(types.GET_CUSTOM_FIELDS_REQUEST, getBusinessCustomFields),
    takeLatest(types.CREATE_BUSINESS_REQUEST,addBusiness)
  ]);
}
