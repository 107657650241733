import types from './types';

/**
 * Get All Customer Transactions
 * @param customerId
 * @return {{payload: *, type: string}}
 */
const getCustomerTxns = (customerId) => ({
  type: types.GET_ALL_TXN_REQUEST,
  payload: customerId,
});

/**
 *
 * @param txnPayload
 * @param onSuccess
 * @param onFailure
 * @return {{payload: *, onFailure: *, type: string, onSuccess: *}}
 */
const addCustomerTxn = (txnPayload, onSuccess, onFailure) => ({
  type: types.CREATE_TXN_REQUEST,
  payload: txnPayload,
  onSuccess,
  onFailure,
});

const deleteCustomerTxn = (txnPayload, onSuccess) => ({
  type: types.DELETE_TXN_REQUEST,
  payload: txnPayload,
  onSuccess,
  
});

/**
 * update txn of customer
 * @param txnPayload
 * @return {{payload: *, type: string}}
 */
const updateCustomerTxn = (txnId, txnPayload, onSuccess, onFailure) => ({
  type: types.UPDATE_TXN_REQUEST,
  txnId,
  payload: txnPayload,
  onSuccess,
  onFailure,
});

const updateTransactionSuccess = (payload) => (
  {
    type:types.GET_ALL_TXN_SUCCESS,
    payload
  }
)

const addCustomerWriteoffTxn = (txnPayload, onSuccess, onFailure) => ({
  type: types.CREATE_WRITEOFF_TXN_REQUEST,
  payload: txnPayload,
  onSuccess,
  onFailure,
});

export { getCustomerTxns, addCustomerTxn, updateCustomerTxn,updateTransactionSuccess,addCustomerWriteoffTxn,deleteCustomerTxn };
