import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { fetchUser, updateDevice } from './account.service';

export const accountAdapter = new createEntityAdapter();
const initialState = accountAdapter.getInitialState({
  isAuthenticate: false,
  user: null,
  device: null,
  isLoading: false,
  error: null,
});

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUser.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = { ...state.user, ...action.payload };
      state.isAuthenticate = true;
      accountAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(fetchUser.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(updateDevice.fulfilled, (state, action) => {
      state.device = action.payload;
    });
  },
});

/** Actions */
export { fetchUser, updateDevice };

/** Reducer */
export default accountSlice.reducer;

/** Selectors */
