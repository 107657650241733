import Api from '../../services/Api';
import { ApiConstant } from '../../constants';
import { createAsyncThunk } from '@reduxjs/toolkit';

const fetchUser = createAsyncThunk('account/fetch', async () => {
  const user = await Api.get(ApiConstant.GET_ACCOUNT);
  console.log('user', user);
  return user;
});

const updateDevice = createAsyncThunk(
  'account/fetchDevice',
  async (_device) => {
    const device = await Api.post(ApiConstant.DEVICE, _device);
    return device;
  }
);

export { fetchUser, updateDevice };
