import { combineReducers } from 'redux';
import accountReducer from './Account';
import paymentPlanReducer from './PaymentPlan/paymentPlan.reducer';
import { businessReducer } from './Business';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { customerReducer } from './Customer';
import { txnReducer } from './Transaction';
import { memberReducer } from './Memeber';
import systemConfigReducer from './SystemConfig/systemConfig.reducer';
import billInvoiceReducer from './BillInvoice/billInvoice.reducer';
import { subscriptionReducer } from './Subscription';

const customerPersistConfig = {
  key: 'customerState',
  storage: storage,
  // stateReconciler: hardSet,
  blacklist: ['customerFilters'],
};

const subscriptionPersistConfig = {
  key: 'subscriptionState',
  storage: storage,
};

const appReducer = combineReducers({
  account: accountReducer,
  paymentPlan: paymentPlanReducer,
  business: businessReducer,
  customer: persistReducer(customerPersistConfig, customerReducer),
  systemConfig: systemConfigReducer,
  transaction: txnReducer,
  staffMember: memberReducer,
  billInvoice: billInvoiceReducer,
  premiumPlans: persistReducer(subscriptionPersistConfig, subscriptionReducer),
});

const reducer = (state, action) => {
  if (action?.type === 'auth/logout11') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default reducer;
