import React, { useEffect, useState } from 'react';
import { useAccount } from '../hooks';

export const useChatService = () => {
  const { user: account } = useAccount();
  const [user, setUser] = useState();

  useEffect(() => {
    if (account) {
      const data = {
        firstName: account.firstName,
        lastName: account.lastName,
        restoreId: account.id,
        externalId: account.id,
        email: account.email || account.contactEmail,
        mobile: account.mobile,
      };
      setUser(data);
      window.fcWidget && loadUser(data);
    }
  }, [account]);
  const init = async () => {
    if (window.fcWidget) {
      window.fcWidget.init({
        token: 'd22c8a93-0ed6-42ea-b9fc-f61f33970d62',
        host: 'https://wchat.freshchat.com',
        externalId: user?.id,
        restoreId: user?.id,
        firstName: user?.firstName, // user’s first name
        lastName: user?.lastName, // user’s last name
        email: user?.email, // user’s email address
        phone: user?.mobile, // phone number without country code
        phoneCountryCode: account?.callingCode, // phone’s country code
        sideId: 'web.meekhata.com',
        tags: ['web', 'web_reg'],
        onInit: loadWidget,
      });
    } else {
      window.fcSettings = {
        token: 'd22c8a93-0ed6-42ea-b9fc-f61f33970d62',
        host: 'https://wchat.freshchat.com',
        externalId: user?.id,
        restoreId: user?.id,
        sideId: 'web.meekhata.com',
        onInit: loadWidget,
      };
    }
  };

  const showChat = () => {
    // window.fcWidget && window.fcWidget.open();
    window.fcWidget && window.fcWidget.show();
  };
  const hideChat = () => {
    window.fcWidget && window.fcWidget.close();
    window.fcWidget && window.fcWidget.hide();
  };
  /*

  /*

   * @method loadWidget - Load the widget
   */
  const loadWidget = () => {
    window.fcWidget.on(
      'widget:loaded',
      function () {
        // hideChat();
        // user && loadUser(user);
      },
      function () {
        // Error Loading Widget
      }
    );

    window.fcWidget.on('widget:closed', function (resp) {
      console.log('Widget Closed');
      // window.fcWidget.hide();
    });
  };
  /*
   * @method loadUser - Load the widget user
   * @param Object user - site user Object
   */
  const loadUser = async (user) => {
    // Check the user
    window.fcWidget &&
      window.fcWidget.user.get().then(
        function (response) {
          validateUser(response, user);
        },
        function (error) {
          validateUser(error, user);
        }
      );
  };
  /*
   * @method validateUser - Validate the Loaded User
   * @param Object response - widget user Object
   * @param Object user - site user Object
   */
  const validateUser = async (response, user) => {
    var status = response && response.status,
      rstId = user && user.restoreId,
      widget = window.fcWidget;
    if (status === 200) {
      updateUser(response, user);
    }
    if ([401, 403, 404].includes(status) && !rstId) {
      /*
       * User doesn't exist
       * Use this block only if you want to create the user on load
       * Otherwise comment this
       */
      widget.user.create().then(
        function (resp) {
          updateUser(resp, user);
        },
        (err) => {
          console.log('Error creating user');
        }
      );
    } else if ([401, 404, 409].includes(status) && rstId) {
      /*
       * Data Mismatch : Can't load the user for the given config
       * Reset restoreId if exist so a new user will be created when there is mismatch
       */
      user.restoreId = void 0;
      widget.user.create().then(
        function (resp) {
          updateUser(resp, user);
        },
        (err) => {
          console.log('Error creating user');
        }
      );
    }
  };
  /*
   * Freshchat Widget Update Method
   * @param Object response - widget user Object
   * @param Object user - site user Object
   */
  const updateUser = async (response, user) => {
    var data = response && response.data,
      externalId = user && user.externalId,
      restoreId = user && user.restoreId;
    if (data) {
      let rstId = data.restoreId,
        extId = data.externalId || data.identifier;
      if (externalId && externalId === extId && restoreId !== rstId) {
        /*
         * Identified a mismatch between widget user and site user credentials
         * Update restoreId for the site user
         */
        // `Replace this with your ajax call`
        // window.updateSiteUser(rstId).then(function (resp) {
        //     // On Success update the local site user object
        //     if (resp && user) {
        //         user.restoreId = rstId;
        //         console.log(`Restore ID updated for user ${rstId}`);
        //     }
        // });
        /*
         * Update widget user custom parameters here
         */
        window.fcWidget.user
          .update({
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            meta: {
              Country: 'IN',
              Plan: 'Freemium',
            },
          })
          .then(function (response) {
            console.log('window.fcWidget', response);
          });
      }
    }
  };
  return {
    init,
    showChat,
    hideChat,
    loadUser,
    updateUser,
    validateUser,
  };
};
