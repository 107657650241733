import Types from './types.js';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../services/Api.js';
import ApiConstant from '../../constants/api.constant.js';

function* getAllPremiumPlans() {
  yield put({ type: Types.GET_PREMIUM_PLAN_IN_PROGRESS });
  try {
    const response = yield call(Api.get, ApiConstant.GET_PREMIUM_PLANS);
    if (response && response.data) {
      yield put({
        type: Types.GET_PREMIUM_PLAN_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: Types.GET_PREMIUM_PLAN_FAILURE,
        payload: response.error,
      });
    }
  } catch (error) {
    yield put({
      type: Types.GET_PREMIUM_PLAN_FAILURE,
      payload: error,
    });
  }
}

function* getAddOns() {
  yield put({ type: Types.GET_ADD_ON_IN_PROGRESS });
  try {
    const response = yield call(Api.get, ApiConstant.GET_ADDONS);
    if (response && response.data) {
      yield put({
        type: Types.GET_ADD_ON_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: Types.GET_ADD_ON_FAILURE,
        payload: response.error,
      });
    }
  } catch (error) {
    yield put({
      type: Types.GET_ADD_ON_FAILURE,
      payload: error,
    });
  }
}

export function* watchSubscriptionPlans() {
  yield all([
    takeLatest(Types.GET_PREMIUM_PLAN_REQUEST, getAllPremiumPlans),
    takeLatest(Types.GET_ADD_ON_REQUEST, getAddOns),
  ]);
}
