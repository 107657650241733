import React from 'react';
import PropTypes from 'prop-types';
import { ProfileAvatar } from '../Common';
import { Row, Tooltip } from 'antd';
import Text from 'antd/lib/typography/Text';
import dayjs from 'dayjs';

const AvatarListItem = ({ icon, title, subTitle, itemKey, handleClick }) => {
  return (
    <Tooltip title={'click on name to see transactions.'}>
      <div className="d-flex w-100" key={itemKey} onClick={handleClick}>
        <div className="mr-2">
          <ProfileAvatar size={'large'} title={title} src={icon} />
        </div>
        <div className="w-100">
          <Row justify={'space-between'}>
            <div className="font-medium text-truncate text-capitalize" style={{color:'black'}}>{title}</div>
          </Row>
          <Row justify="space-between">
            <Text className="text-small">{subTitle}</Text>
          </Row>
        </div>
      </div>
    </Tooltip>
  );
};

AvatarListItem.propTypes = {
  itemKey: PropTypes.any,
  icon: PropTypes.any,
  title: PropTypes.string,
  subTitle: PropTypes.string,
};

export default AvatarListItem;
