import types from './types';

/**
 * Action for Customer List
 * @return {{type: string}}
 */
const getAllCustomers = (payload) => ({
  type: types.GET_ALL_CUSTOMER_REQUEST,
  payload,
});
const getAllCustomers1 = (payload) => ({
  type: types.GET_ALL_CUSTOMER_REQUEST1,
  payload,
});

const applyCustomerFilter = (filter) => ({
  type: types.APPLY_CUSTOMER_FILTER,
  payload: filter,
});

const emptyCustomerList = () => ({
  type: types.EMPTY_CUSTOMER_LIST,
});
const getBusinessBalance = () => ({
  type: types.GET_BUSINESS_BALANCE_REQUEST,
});

const addCustomer = (customer, onSuccess, onError) => ({
  type: types.CREATE_CUSTOMER_REQUEST,
  payload: customer,
  onSuccess,
  onError,
});

const addBulkCustomer = (customerList, onSuccess, onError) => ({
  type: types.ADD_BULK_CUSTOMER_REQUEST,
  payload: customerList,
  onSuccess,
  onError,
});

const bulkSettleAccounts = (txnsPayload, onSuccess, onFailure) => ({
  type: types.BULK_SETTLE_TXN_REQUEST,
  payload: txnsPayload,
  onSuccess,
  onFailure,
});

const updateCustomer = (customerId, customer, onSuccess, onError) => ({
  type: types.UPDATE_CUSTOMER_REQUEST,
  customerId: customerId,
  payload: customer,
  onSuccess,
  onError,
});

const setCustomer = (customer) => ({
  type: types.UPDATE_CUSTOMER_SUCCESS,
  payload: customer,
});

const deleteCustomer = (customerId, onSuccess, onError) => ({
  type: types.DELETE_CUSTOMER_REQUEST,
  customerId: customerId,
  onSuccess,
  onError,
});

const bulkDeleteCustomers = (customerIds, onSuccess, onError) => ({
  type: types.DELETE_BULK_CUSTOMER_REQUEST,
  customerIds: customerIds,
  onSuccess,
  onError,
});

const AddBulkCustomers = (customers, onSuccess, onError) => ({
  type: types.ADD_BULK_CUSTOMER_REQUEST,
  payload: customers,
  onSuccess,
  onError,
});

export {
  getAllCustomers,
  getBusinessBalance,
  addCustomer,
  addBulkCustomer,
  updateCustomer,
  deleteCustomer,
  AddBulkCustomers,
  bulkSettleAccounts,
  bulkDeleteCustomers,
  applyCustomerFilter,
  setCustomer,
  emptyCustomerList,
  getAllCustomers1,
};
