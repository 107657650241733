import Types from './types';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../services/Api';
import ApiConstant from '../../constants/api.constant';
import { message } from 'antd';
import { buildUrl } from '../state.utils';

/**
 * Member GET getAllMembers list
 * @return {Generator<*, void, ?>}
 */
function* getAllMembers() {
  yield put({ type: Types.GET_MEMBER_IN_PROGRESS });
  try {
    const response = yield call(Api.get, ApiConstant.GET_STAFF);
    if (response && response.data) {
      yield put({
        type: Types.GET_MEMBER_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: Types.GET_MEMBER_FAILURE,
        payload: response.error,
      });
    }
  } catch (error) {
    yield put({
      type: Types.GET_MEMBER_FAILURE,
      payload: error,
    });
  }
}

function* addStaffMember(action) {
  yield put({ type: Types.ADD_MEMBER_IN_PROGRESS });
  try {
    const response = yield call(Api.post, ApiConstant.ADD_STAFF, action.payload);
    if (response && response.data) {
      action.onSuccess && action.onSuccess(response.data);
      message.success('New Staff Added');
      yield put({
        type: Types.ADD_MEMBER_SUCCESS,
        payload: response.data,
      });
    } else {
      action.onFailed && action.onFailed(response.error);
      message.error(response.error);
      yield put({
        type: Types.ADD_MEMBER_FAILURE,
        payload: response.error,
      });
    }
  } catch (error) {
    action.onFailed && action.onFailed(error);
    message.error(error?.title);
    yield put({
      type: Types.ADD_MEMBER_FAILURE,
      payload: error,
    });
  }
}

function* updateStaffMember(action) {
  yield put({ type: Types.UPDATE_MEMBER_IN_PROGRESS });
  try {
    const response = yield call(Api.put, ApiConstant.UPDATE_STAFF, action.payload);
    if (response && response.data) {
      action.onSuccess && action.onSuccess(response);
      message.success('Updated Staff Successfully!');
      yield put({
        type: Types.UPDATE_MEMBER_SUCCESS,
        payload: response,
      });
    } else {
      action.onFailed && action.onFailed(response.error);
      message.error(response.error);
      yield put({
        type: Types.UPDATE_MEMBER_FAILURE,
        payload: response.error,
      });
    }
  } catch (error) {
    action.onFailed && action.onFailed(error);
    message.error(error?.title);
    yield put({
      type: Types.UPDATE_MEMBER_FAILURE,
      payload: error,
    });
  }
}

function* deleteStaffMember(action) {
  yield put({ type: Types.DELETE_MEMBER_IN_PROGRESS });
  const url = buildUrl(ApiConstant.DELETE_STAFF, {
    employeeId: action.employeeId,
  });
  try {
    const response = yield call(Api.delete, url);
    action.onSuccess && action.onSuccess(response.data);
    message.success('Deleted Staff Successfully!');
    yield put({
      type: Types.DELETE_MEMBER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    action.onFailed && action.onFailed(error);
    message.error(error?.title);
    yield put({
      type: Types.DELETE_MEMBER_FAILURE,
      payload: error,
    });
  }
}

export function* watchStaffMember() {
  yield all([
    takeLatest(Types.GET_MEMBER_REQUEST, getAllMembers),
    takeLatest(Types.ADD_MEMBER_REQUEST, addStaffMember),
    takeLatest(Types.UPDATE_MEMBER_REQUEST, updateStaffMember),
    takeLatest(Types.DELETE_MEMBER_REQUEST, deleteStaffMember),
  ]);
}
