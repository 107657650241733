import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

const initialState = {
  paymentPlans: [],
};

const paymentPlanSlice = createSlice({
  name: 'paymentPlan',
  initialState,
  reducers: {
    setPaymentPlans: (state, action) => {
      state.paymentPlans = action.payload?.reverse();
    },
    setNewPaymentPlan: (state, action) => {
      state.paymentPlans = [action.payload?.plan, ...state.paymentPlans];
    },
    setUpdatePaymentPlan: (state, action) => {
      state.paymentPlans = state.paymentPlans?.map((paymentPlan) =>
        action.payload?.plan?.id === paymentPlan.id ? action.payload?.plan : paymentPlan
      );
    },
    setDeletePaymentPlan: (state, action) => {
      state.paymentPlans = state.paymentPlans?.filter(
        (paymentPlan) => action.payload != paymentPlan.id
      );
    },
  },
});

/** Actions */
export const paymentPlanActions = paymentPlanSlice.actions;

/** Reducer */
export default paymentPlanSlice.reducer;

/** Selectors */
