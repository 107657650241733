/**
 *  Constants used to Create customer profile
 */

const CREATE_CUSTOMER_REQUEST = 'customer/CREATE_CUSTOMER_REQUEST';
const CREATE_CUSTOMER_SUCCESS = 'customer/CREATE_CUSTOMER_SUCCESS';
const CREATE_CUSTOMER_FAILURE = 'customer/CREATE_CUSTOMER_FAILURE';
const CREATE_CUSTOMER_IN_PROGRESS = 'customer/CREATE_CUSTOMER_IN_PROGRESS';

/**
 *  Constants used to Add bulk customer
 */

const ADD_BULK_CUSTOMER_REQUEST = 'customer/ADD_BULK_CUSTOMER_REQUEST';
const ADD_BULK_CUSTOMER_SUCCESS = 'customer/ADD_BULK_CUSTOMER_SUCCESS';
const ADD_BULK_CUSTOMER_FAILURE = 'customer/ADD_BULK_CUSTOMER_FAILURE';
const ADD_BULK_CUSTOMER_IN_PROGRESS = 'customer/ADD_BULK_CUSTOMER_IN_PROGRESS';

/**
 *  Constants used to Delete bulk customer
 */
const DELETE_BULK_CUSTOMER_REQUEST = 'customer/DELETE_BULK_CUSTOMER_REQUEST';
const DELETE_BULK_CUSTOMER_SUCCESS = 'customer/DELETE_BULK_CUSTOMER_SUCCESS';
const DELETE_BULK_CUSTOMER_FAILURE = 'customer/DELETE_BULK_CUSTOMER_FAILURE';
const DELETE_BULK_CUSTOMER_IN_PROGRESS = 'customer/DELETE_BULK_CUSTOMER_IN_PROGRESS';

/**
 *  Constants used to Update customer profile
 */

const UPDATE_CUSTOMER_REQUEST = 'customer/UPDATE_CUSTOMER_REQUEST';
const UPDATE_CUSTOMER_SUCCESS = 'customer/UPDATE_CUSTOMER_SUCCESS';
const UPDATE_CUSTOMER_FAILURE = 'customer/UPDATE_CUSTOMER_FAILURE';
const UPDATE_CUSTOMER_IN_PROGRESS = 'customer/UPDATE_CUSTOMER_IN_PROGRESS';

/**
 *  Constants used to Update customer profile
 */

const DELETE_CUSTOMER_REQUEST = 'customer/DELETE_CUSTOMER_REQUEST';
const DELETE_CUSTOMER_SUCCESS = 'customer/DELETE_CUSTOMER_SUCCESS';
const DELETE_CUSTOMER_FAILURE = 'customer/DELETE_CUSTOMER_FAILURE';
const DELETE_CUSTOMER_IN_PROGRESS = 'customer/DELETE_CUSTOMER_IN_PROGRESS';

/**
 *  Constants used to GET all customer for
 *  current loggedIN
 */

const GET_ALL_CUSTOMER_REQUEST = 'customer/GET_ALL_CUSTOMER_REQUEST';
const GET_ALL_CUSTOMER_SUCCESS = 'customer/GET_ALL_CUSTOMER_SUCCESS';
const GET_ALL_CUSTOMER_FAILURE = 'customer/GET_ALL_CUSTOMER_FAILURE';
const GET_ALL_CUSTOMER_IN_PROGRESS = 'customer/GET_ALL_CUSTOMER_IN_PROGRESS';
const GET_ALL_CUSTOMER_REQUEST1 = 'customer/GET_ALL_CUSTOMER_REQUEST1';

const APPLY_CUSTOMER_FILTER = 'customer/APPLY_CUSTOMER_FILTER';

/**
 *  Constants used to GET Balance for
 *  current loggedIN
 */

const GET_BUSINESS_BALANCE_REQUEST = 'customer/GET_BUSINESS_BALANCE_REQUEST';
const GET_BUSINESS_BALANCE_SUCCESS = 'customer/GET_BUSINESS_BALANCE_SUCCESS';
const GET_BUSINESS_BALANCE_FAILURE = 'customer/GET_BUSINESS_BALANCE_FAILURE';
const GET_BUSINESS_BALANCE_IN_PROGRESS = 'customer/GET_BUSINESS_BALANCE_IN_PROGRESS';

/**
 *  Constants used to settleup bulk accounts transaction
 */
const BULK_SETTLE_TXN_REQUEST = 'customer/BULK_SETTLE_TXN_REQUEST';
const BULK_SETTLE_TXN_IN_PROGRESS = 'customer/BULK_SETTLE_TXN_IN_PROGRESS';
const BULK_SETTLE_TXN_SUCCESS = 'customer/BULK_SETTLE_TXN_SUCCESS';
const BULK_SETTLE_TXN_FAILURE = 'customer/BULK_SETTLE_TXN_FAILURE';

const EMPTY_CUSTOMER_LIST = 'customer/EMPTY_CUSTOMER_LIST';
export default {
  GET_BUSINESS_BALANCE_REQUEST,
  GET_BUSINESS_BALANCE_IN_PROGRESS,
  GET_BUSINESS_BALANCE_SUCCESS,
  GET_BUSINESS_BALANCE_FAILURE,

  GET_ALL_CUSTOMER_REQUEST,
  GET_ALL_CUSTOMER_IN_PROGRESS,
  GET_ALL_CUSTOMER_SUCCESS,
  GET_ALL_CUSTOMER_FAILURE,

  CREATE_CUSTOMER_REQUEST,
  CREATE_CUSTOMER_IN_PROGRESS,
  CREATE_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER_FAILURE,

  UPDATE_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_IN_PROGRESS,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAILURE,

  DELETE_CUSTOMER_REQUEST,
  DELETE_CUSTOMER_IN_PROGRESS,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_FAILURE,

  ADD_BULK_CUSTOMER_REQUEST,
  ADD_BULK_CUSTOMER_IN_PROGRESS,
  ADD_BULK_CUSTOMER_SUCCESS,
  ADD_BULK_CUSTOMER_FAILURE,

  DELETE_BULK_CUSTOMER_REQUEST,
  DELETE_BULK_CUSTOMER_IN_PROGRESS,
  DELETE_BULK_CUSTOMER_SUCCESS,
  DELETE_BULK_CUSTOMER_FAILURE,

  APPLY_CUSTOMER_FILTER,

  BULK_SETTLE_TXN_REQUEST,
  BULK_SETTLE_TXN_IN_PROGRESS,
  BULK_SETTLE_TXN_SUCCESS,
  BULK_SETTLE_TXN_FAILURE,

  EMPTY_CUSTOMER_LIST,
  GET_ALL_CUSTOMER_REQUEST1
};
