import './polyfill';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './utils/i18n';
import { Provider } from 'react-redux';
import initStore from './store';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { IconContext } from 'react-icons';
import Bugsnag from '@bugsnag/js';
import { BugsnagService } from './services/BugsnagService';
const { store, persistor } = initStore();
BugsnagService.init();

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

ReactDOM.render(
  // <React.StrictMode>
  <Suspense fallback={''}>
    <ErrorBoundary>
      <IconContext.Provider value={{ className: 'anticon' }}>
        <Provider store={store}>
          <PersistGate loading={<b>Loading...</b>} persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
      </IconContext.Provider>
    </ErrorBoundary>
  </Suspense>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
