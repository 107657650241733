import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { TxnType } from '../../constants/app.constant';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
const currencyMap = {
  INR: '₹',
  USD: '$',
};
const Amount = (props) => {

  const {selected } = useSelector(({ business }) => business);

  let className = props.className;
  const prefix = currencyMap[selected.currency];
  if (props.value < 0 || props.txnType === TxnType.DEBIT) {
    className += ' text-danger';
  } else {
    className += ' text-success';
  }
  const allProps = { ...props };
  delete allProps.className;
  delete allProps.txnType;
  delete allProps.currency;
  return (
    <CurrencyFormat
    allowNegative={false}
      style={{...allProps.style}}
      displayType={'text'}
      thousandSeparator={true}
      decimalScale={2}
      prefix={prefix}
      {...allProps}
      value={props.value}
      renderText={(value) => <span style={{...allProps.style}} className={classNames('mk-amount', className)}>{value}</span>}
    />
  );
};

export default Amount;
