/**
 * GET actions
 */
import Types from './types.js';

/**
 * Action for GET
 * @return {{type: string}}
 */
const getPremiumPlans = () => ({
  type: Types.GET_PREMIUM_PLAN_REQUEST,
});

const getAddons = () => ({
  type: Types.GET_ADD_ON_REQUEST,
});

export default { getPremiumPlans, getAddons };
