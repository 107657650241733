import { all, call, put, takeLatest } from 'redux-saga/effects';
import types from './types';
import { Api } from '../../services';
import { ApiConstant } from '../../constants';
import { buildUrl } from '../state.utils';

function* getCustomerTxns(action) {
  const url = yield call(buildUrl, ApiConstant.GET_CUSTOMER_TXNS, {
    customerId: action.payload,
  });

  yield put({ type: types.GET_ALL_TXN_IN_PROGRESS });
  try {
    const response = yield call(Api.get, url);
    yield put({
      type: types.GET_ALL_TXN_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: types.GET_ALL_TXN_FAILURE,
      error: error,
    });
  }
}

function* AddCustomerTxn(action) {
  const url = yield call(buildUrl, ApiConstant.ADD_CUSTOMER_TXN, {
    customerId: action.payload.customerId,
  });

  yield put({ type: types.CREATE_TXN_IN_PROGRESS });
  try {
    const response = yield call(Api.post, url, action.payload);
    action.onSuccess && action.onSuccess(response);
    yield put({
      type: types.CREATE_TXN_SUCCESS,
      payload: response,
    });
    yield put({ type: 'customer/GET_ALL_CUSTOMER_REQUEST1' });
  } catch (error) {
    action.onError && action.onError(action.error);
    yield put({
      type: types.CREATE_TXN_FAILURE,
      error: error,
    });
  }
}

function* AddCustomerWriteOffTxn(action) {
  const url = yield call(buildUrl, ApiConstant.ADD_WRITEOFF_TXN, {
    customerId: action.payload.customerId,
  });

  yield put({ type: types.CREATE_WRITEOFF_TXN_IN_PROGRESS });
  try {
    const response = yield call(Api.post, url, action.payload);
    action.onSuccess && action.onSuccess(response);
    yield put({
      type: types.CREATE_TXN_SUCCESS,
      payload: response,
    });
    yield put({ type: 'customer/GET_ALL_CUSTOMER_REQUEST1' });
  } catch (error) {
    action.onError && action.onError(action.error);
    yield put({
      type: types.CREATE_TXN_FAILURE,
      error: error,
    });
  }
}

function* updateCustomerTxn(action) {
  const url = yield call(buildUrl, ApiConstant.UPDATE_CUSTOMER_TXN, {
    txnId: action.txnId,
  });

  yield put({ type: types.UPDATE_TXN_IN_PROGRESS });
  try {
    const response = yield call(Api.put, url, action.payload);
    action.onSuccess && action.onSuccess(action.payload);
    yield put({
      type: types.UPDATE_TXN_SUCCESS,
      payload: response,
    });
  } catch (error) {
    action.onError && action.onError(action.payload);
    yield put({
      type: types.UPDATE_TXN_FAILURE,
      error: error,
    });
  }
}

function* DeleteCustomerTxn(action) {
  console.log("devyani",action)
  const url = yield call(buildUrl, ApiConstant.DELETE_TRANSACTION, {
    customerId: action.payload.customerId,
    txnId: action.payload.txnId
  });

  yield put({ type: types.DELETE_TXN_IN_PROGRESS });
  try {
    const response = yield call(Api.delete, url);
    action.onSuccess && action.onSuccess(response);
    yield put({
      type: types.DELETE_TXN_SUCCESS,
      payload: response,
    });
    yield put({ type: 'customer/GET_ALL_CUSTOMER_REQUEST1' });
  } catch (error) {
    action.onError && action.onError(action.error);
    yield put({
      type: types.DELETE_TXN_FAILURE,
      error: error,
    });
  }
}

export function* watchTransaction() {
  yield all([
    takeLatest(types.GET_ALL_TXN_REQUEST, getCustomerTxns),
    takeLatest(types.CREATE_TXN_REQUEST, AddCustomerTxn),
    takeLatest(types.UPDATE_TXN_REQUEST, updateCustomerTxn),
    takeLatest(types.CREATE_WRITEOFF_TXN_REQUEST,AddCustomerWriteOffTxn),
    takeLatest(types.DELETE_TXN_REQUEST, DeleteCustomerTxn),
  ]);
}
