import { createSelector } from 'reselect';

const _customFields = (state) => state.business.customFields;
const getCustomFields = createSelector(
  [_customFields],
  (customFields) => customFields
);

export default {
  getCustomFields,
};
