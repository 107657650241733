import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

const initialState = {
  billInvoice: [],
};

const billInvoiceSlice = createSlice({
  name: 'billInvoice',
  initialState,
  reducers: {
    setBillInvoice: (state, action) => {
      state.billInvoice = action.payload?.data;
    },
    setUpdateBillInvoice: (state, action) => {
      state.billInvoice = state.billInvoice?.map((paymentPlan) =>
        action.payload?.id === paymentPlan.id ? action.payload?.data : paymentPlan
      );
    },
  },
});

/** Actions */
export const billInvoiceActions = billInvoiceSlice.actions;

/** Reducer */
export default billInvoiceSlice.reducer;

/** Selectors */
