import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Layout from 'antd/es/layout/layout';
import Text from 'antd/es/typography/Text';
import { Button } from 'antd';
import './PageHeader.scss';
import { RiArrowLeftLine } from 'react-icons/all';
import { useHistory, useLocation } from 'react-router';
const { Header } = Layout;
const PageHeader = (props) => {
  const history = useHistory();
  const { title, onBack, children, hideBack } = props;

  const onBackBtnHandler = () => {
    onBack ? onBack() : history.goBack();
  };
  return (
    <Header className="PageHeader border-bottom">
      {hideBack ? null : (
        <Button
          size={'large'}
          type={'text'}
          onClick={onBackBtnHandler}
          className="backBtn"
          icon={<RiArrowLeftLine />}
        ></Button>
      )}
      <Text style={{color:'black',fontWeight:'bold'}} className={'text-xlarge ml-2 text-bold'}>{title}</Text>
      {children}
    </Header>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string,
  hideBack: PropTypes.bool,
};

export default memo(PageHeader);
