import React, { memo } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

const DUE_TEMPLATE = {
  BEFORE: 'Due in ${days} days',
  AFTER: 'Overdue by ${days} days',
  TODAY: 'Due today',
};
const DueText = (props) => {
  const unit = props.unit || 'day';
  const isBefore = dayjs().isBefore(props.dueDate, unit);
  const isSame = dayjs().isSame(props.dueDate, unit);
  const isAfter = dayjs().isAfter(props.dueDate, unit);
  const diffInDay = dayjs(props.dueDate).diff(dayjs(), unit, true);
  const days = Math.round(Math.abs(diffInDay));
  const className =
    'mk-due-text ' +
    props.className +
    (props.colorCodes
      ? ' ' +
        (isAfter ? 'text-danger' : isBefore && days < 7 ? 'text-warning' : '')
      : '');

  if (!props.dueDate) return null;
  return (
    <span className={className}>
      {
        // dayjs().to(props.dueDate)
        isSame
          ? DUE_TEMPLATE.TODAY
          : isBefore
          ? DUE_TEMPLATE.BEFORE.interpolate({ days })
          : isAfter
          ? DUE_TEMPLATE.AFTER.interpolate({ days })
          : null
      }
    </span>
  );
};

DueText.propTypes = {
  dueDate: PropTypes.string,
  className: PropTypes.string,
};

export default memo(DueText);
