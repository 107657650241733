import types from './types';

const INITIAL_VALUE = {
  selected: null,
  loading: false,
  error: null,
  tags: [],
  groups: [],
  customFields: [],
  list: [],
  listLoading: false,
  listError: false,
};

const reducer = (state = INITIAL_VALUE, action) => {
  switch (action.type) {
    /**
     * GET current loggedIn business details
     */
    case types.GET_BUSINESS_IN_PROGRESS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.GET_BUSINESS_SUCCESS:
      return {
        ...state,
        loading: false,
        selected: action.payload,
        error: null,
      };
    case types.GET_BUSINESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    /**
     * GET All business list
     */
    case types.GET_ALL_BUSINESS_IN_PROGRESS:
      return {
        ...state,
        listLoading: true,
        listError: null,
      };
    case types.GET_ALL_BUSINESS_SUCCESS:
      return {
        ...state,
        listLoading: false,
        list: action.payload,
        listError: null,
      };
    case types.GET_ALL_BUSINESS_FAILURE:
      return {
        ...state,
        listLoading: false,
        listError: action.error,
      };
    /**
     * get business tags
     */
    case types.GET_TAGS_SUCCESS:
      return {
        ...state,
        tags: action.payload,
      };
    case types.SET_GROUPS:
      return {
        ...state,
        groups: action.payload,
      };
    /**
     * get custom fields
     */
    case types.GET_CUSTOM_FIELDS_SUCCESS:
      return {
        ...state,
        customFields: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
