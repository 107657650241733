import React from 'react';
import { useSelector } from 'react-redux';
import { usePrevious } from './index';

export const useCurrentBusiness = () => {
  const businessState = useSelector(({ business }) => business);
  const prevBusinessId = usePrevious(businessState.selected?.id);
  return {
    isBusinessChanged: prevBusinessId != businessState.selected?.id,
    prevBusinessId: prevBusinessId,
    currentBusiness: businessState.selected,
    loading: businessState.loading,
    error: businessState.error,
    tags: businessState.tags,
    groups: businessState.groups,
  };
};
