/**
 *  Constants used to Create transaction
 */

const CREATE_TXN_REQUEST = 'transaction/CREATE_TXN_REQUEST';
const CREATE_TXN_SUCCESS = 'transaction/CREATE_TXN_SUCCESS';
const CREATE_TXN_FAILURE = 'transaction/CREATE_TXN_FAILURE';
const CREATE_TXN_IN_PROGRESS = 'transaction/CREATE_TXN_IN_PROGRESS';

/**
 *  Constants used to Update transaction
 */

const UPDATE_TXN_REQUEST = 'transaction/UPDATE_TXN_REQUEST';
const UPDATE_TXN_SUCCESS = 'transaction/UPDATE_TXN_SUCCESS';
const UPDATE_TXN_FAILURE = 'transaction/UPDATE_TXN_FAILURE';
const UPDATE_TXN_IN_PROGRESS = 'transaction/UPDATE_TXN_IN_PROGRESS';

/**
 *  Constants used to Update transaction
 */

const DELETE_TXN_REQUEST = 'transaction/DELETE_TXN_REQUEST';
const DELETE_TXN_SUCCESS = 'transaction/DELETE_TXN_SUCCESS';
const DELETE_TXN_FAILURE = 'transaction/DELETE_TXN_FAILURE';
const DELETE_TXN_IN_PROGRESS = 'transaction/DELETE_TXN_IN_PROGRESS';

/**
 *  Constants used to GET all transaction for
 *  current loggedIN
 */
const GET_ALL_TXN_REQUEST = 'transaction/GET_ALL_TXN_REQUEST';
const GET_ALL_TXN_SUCCESS = 'transaction//GET_ALL_TXN_SUCCESS';
const GET_ALL_TXN_FAILURE = 'transaction/GET_ALL_TXN_FAILURE';
const GET_ALL_TXN_IN_PROGRESS = 'transaction/GET_ALL_TXN_IN_PROGRESS';

/**
 *  Constants used to Writeoff  transaction
 */

 const CREATE_WRITEOFF_TXN_REQUEST = 'transaction/CREATE_WRITEOFF_TXN_REQUEST';
 const CREATE_WRITEOFF_TXN_SUCCESS = 'transaction/CREATE_WRITEOFF_TXN_SUCCESS';
 const CREATE_WRITEOFF_TXN_FAILURE = 'transaction/CREATE_WRITEOFF_TXN_FAILURE';
 const CREATE_WRITEOFF_TXN_IN_PROGRESS = 'transaction/CREATE_WRITEOFF_TXN_IN_PROGRESS';

export default {
  GET_ALL_TXN_REQUEST,
  GET_ALL_TXN_IN_PROGRESS,
  GET_ALL_TXN_SUCCESS,
  GET_ALL_TXN_FAILURE,

  CREATE_TXN_REQUEST,
  CREATE_TXN_IN_PROGRESS,
  CREATE_TXN_SUCCESS,
  CREATE_TXN_FAILURE,

  UPDATE_TXN_REQUEST,
  UPDATE_TXN_IN_PROGRESS,
  UPDATE_TXN_SUCCESS,
  UPDATE_TXN_FAILURE,

  DELETE_TXN_REQUEST,
  DELETE_TXN_IN_PROGRESS,
  DELETE_TXN_SUCCESS,
  DELETE_TXN_FAILURE,

  CREATE_WRITEOFF_TXN_REQUEST,
  CREATE_WRITEOFF_TXN_SUCCESS,
  CREATE_WRITEOFF_TXN_FAILURE,
  CREATE_WRITEOFF_TXN_IN_PROGRESS



};
