/**
 *  Constants used to GET premium plans
 */
const GET_PREMIUM_PLAN_REQUEST = 'subscription/GET_PREMIUM_PLAN_REQUEST';
const GET_PREMIUM_PLAN_SUCCESS = 'subscription/GET_PREMIUM_PLAN_SUCCESS';
const GET_PREMIUM_PLAN_FAILURE = 'subscription/GET_PREMIUM_PLAN_FAILURE';
const GET_PREMIUM_PLAN_IN_PROGRESS = 'subscription/GET_PREMIUM_PLAN_IN_PROGRESS';

/**
 *  Constants used to GET add ons
 */
const GET_ADD_ON_REQUEST = 'subscription/GET_ADD_ON_REQUEST';
const GET_ADD_ON_SUCCESS = 'subscription/GET_ADD_ON_SUCCESS';
const GET_ADD_ON_FAILURE = 'subscription/GET_ADD_ON_FAILURE';
const GET_ADD_ON_IN_PROGRESS = 'subscription/GET_ADD_ON_IN_PROGRESS';

export default {
  GET_PREMIUM_PLAN_REQUEST,
  GET_PREMIUM_PLAN_SUCCESS,
  GET_PREMIUM_PLAN_FAILURE,
  GET_PREMIUM_PLAN_IN_PROGRESS,

  GET_ADD_ON_REQUEST,
  GET_ADD_ON_SUCCESS,
  GET_ADD_ON_FAILURE,
  GET_ADD_ON_IN_PROGRESS,
};
