import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';

const MkTags = ({ children, color, tagKey, ...props }) => {
  return (
    <Tag
      key={tagKey}
      style={{ fontSize: '10px', lineHeight: '18px' }}
      color={color}
      {...props}
    >
      {children}
    </Tag>
  );
};

MkTags.propTypes = {
  tagKey: PropTypes.any,
  color: PropTypes.string,
};

export default MkTags;
