import axios from 'axios';
import { message } from 'antd';
import { useDispatch } from 'react-redux';
import { clearStorage } from '../utils/helper';

const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT;
axios.defaults.baseURL = API_BASE_URL;

const Api = {
  get: (url, option, query, successCallback, errorCallback) =>
    request({
      url,
      init: {
        method: 'GET',
      },
      query,
      option,
      successCallback,
      errorCallback,
    }),
  post: (url, option, query, successCallback, errorCallback) =>
    request({
      url,
      init: {
        method: 'POST',
      },
      query,
      option,
      successCallback,
      errorCallback,
    }),
  put: (url, option, query, successCallback, errorCallback) =>
    request({
      url,
      init: {
        method: 'PUT',
      },
      query,
      option,
      successCallback,
      errorCallback,
    }),
  patch: (url, option, query, successCallback, errorCallback) =>
    request({
      url,
      init: {
        method: 'PATCH',
      },
      query,
      option,
      successCallback,
      errorCallback,
    }),
  delete: (url, option, query, successCallback, errorCallback) =>
    request({
      url,
      init: {
        method: 'DELETE',
      },
      query,
      option,
      successCallback,
      errorCallback,
    }),
};

/**
 * Header interceptor for each api
 * @param headers
 * @return {{Authorization: string, Accept: string, "Content-Type": string}}
 */
function buildHeaders(headers) {
  const token = localStorage.getItem('id_token');
  const currentBusinessId = localStorage.getItem('currentBusinessId');
  const newHeader = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...headers,
  };

  if (token) {
    newHeader.Authorization = 'Bearer ' + token;
    if (currentBusinessId) {
      newHeader['X-Business'] = currentBusinessId;
    }
  }

  return newHeader;
}

function request(props) {
  const { url, init, query, option, successCallback, errorCallback } = props;
  const fetchUrl = `${API_BASE_URL}${url}`;
  return axios({
    url: fetchUrl,
    method: init.method,
    data: option,
    params: query,
    headers: buildHeaders(init.headers),
    timeout: option && option.timeout ? option.timeout : 10000,
  })
    .then((response) => {
      successCallback && successCallback(response?.data);
      return response?.data;
    })
    .catch(( response ) => {
      // errorCallback && errorCallback(response.data);
      onError(response);
      console.log(response)
    });
}

/**
 * called on api failed
 * @param error
 */
let onError = ({ data, status }) => {
  const error = data?.error || data ;
  console.log(error);
  if (status === 0) {
    message.warn('Please check if you are connected to Internet');
  } else if (status === 400) {
    if (data.error) {
      message.error(error.message);
    }
  } else if (status === 500) {
    message.error({ content: 'Server Error!', key: 'status_500', duration: 2 });
  } else if (status === 503) {
    message.error('Server is down! Please try after sometime');
  } else if (error.code === 4001) {
    message.error({
      content: error.message || "You don't have access, Please contact support!",
      key: 'status_4001',
      duration: 2,
    });
  } else if (status == 401) {
    clearStorage();
  } else {
    console.log(error.message);
  }
  throw error;
};

export default Api;
