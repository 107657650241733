import Types from './types.js';

const initialState = {
  paymentPlans: null,
  addOns: null,
  isLoading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_PREMIUM_PLAN_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case Types.GET_PREMIUM_PLAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        paymentPlans: action.payload,
        error: false,
      };
    case Types.GET_PREMIUM_PLAN_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case Types.GET_ADD_ON_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case Types.GET_ADD_ON_SUCCESS:
      return {
        ...state,
        isLoading: false,
        addOns: action.payload,
        error: false,
      };
    case Types.GET_ADD_ON_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
