import axios from 'axios';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import parse from 'html-react-parser';
import { includes, isEqual, remove } from 'lodash';
import {
  DATE_FORMAT,
  DayOfWeek,
  persistentStorageKeys,
  RecurrenceDayjsKeys,
  RecurrenceType,
} from '../constants/app.constant';
import { AnalyticService } from '../services';
import { reportAxios } from '../services/backend-api';
dayjs.extend(customParseFormat);
String.prototype.interpolate = function (params) {
  const names = Object.keys(params);
  const vals = names.map((e) => params[e]);
  return new Function(...names, `return \`${this}\`;`)(...vals);
};

const groupBy = (xs, key) => {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export function clearStorage() {
  const keys = Object.keys(localStorage);
  remove(keys, function (k) {
    return includes(persistentStorageKeys, k);
  });
  AnalyticService.clearUserProperties();
  keys.forEach((a) => localStorage.removeItem(a));
  delete axios.defaults.headers.common['X-Business'];
  delete reportAxios.defaults.headers.common['X-Business'];
  delete axios.defaults.headers.common.Authorization;
  delete reportAxios.defaults.headers.common.Authorization;
}
/**
 * convert dot notation to neasted objetc
 * eg. {state.id: ''} => {state: {id: ''}}
 * @param obj
 * @return {{}}
 */
function deepen(obj) {
  const result = {};

  // For each object path (property key) in the object
  for (const objectPath in obj) {
    // Split path into component parts
    const parts = objectPath.split('.');

    // Create sub-objects along path as needed
    let target = result;
    while (parts.length > 1) {
      const part = parts.shift();
      target = target[part] = target[part] || {};
    }

    // Set value at end of path
    target[parts[0]] = obj[objectPath];
  }

  return result;
}
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const toLocalDate = (dateString, format) => {
  if (!dateString) return '';
  return dayjs(dateString).format(format || DATE_FORMAT);
};

export function parseDate(strDate, format = 'DD-MM-YYYY') {
  if (!strDate) return dayjs();
  if (dayjs(strDate, 'DD-MM-YYYY').isValid()) {
    return dayjs(strDate, 'DD-MM-YYYY');
  } else if (dayjs(strDate, 'DD/MM/YYYY').isValid()) {
    return dayjs(strDate, 'DD-MM-YYYY');
  } else if (dayjs(strDate, 'DD-MM-YY').isValid()) {
    return dayjs(strDate, 'DD-MM-YY');
  } else if (dayjs(strDate, 'DD/MM/YY').isValid()) {
    return dayjs(strDate, 'DD/MM/YY');
  } else if (dayjs(strDate, format).isValid()) {
    return dayjs(strDate, format);
  } else {
    return dayjs();
  }
}
export const getKeyByValue = (obj, value) => Object.keys(obj).find((key) => obj[key] === value);

function toCapitalize(str) {
  if (!str) return str;
  let i,
    frags = str.split('_');
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)?.toLowerCase();
  }
  return frags.join(' ');
}

const toServerDate = () => {};

const parseQueryParams = (queryString) => {
  const queryParams = {};
  const query = new URLSearchParams(queryString);
  for (let param of query.entries()) {
    queryParams[param[0]] = param[1];
  }
  return queryParams;
};

const removeFieldFromUrlParam = (key, history) => {
  let currentUrlParams = new URLSearchParams(window.location.search);
  currentUrlParams.delete(key);
  history.replace({
    pathname: window.location.pathname,
    search: currentUrlParams.toString(),
  });
};

const setObjectToUrlParam = (obj, history) => {
  if (obj && Object.keys(obj).length) {
    let currentUrlParams = new URLSearchParams(window.location.search);
    Object.keys(obj).forEach((keyItem) => {
      if (obj[keyItem] !== null || obj[keyItem] !== undefined) {
        currentUrlParams.set(keyItem, obj[keyItem]);
      } else {
        currentUrlParams.delete(keyItem);
      }
    });
    history.replace({
      pathname: window.location.pathname,
      search: currentUrlParams.toString(),
    });
  }
};
function buildUrl(string, obj) {
  return buildString(string, obj);
}

export function buildString(string, obj) {
  let s = string;
  for (const prop in obj) {
    s = s.replace(new RegExp('{' + prop + '}', 'g'), obj[prop]);
  }
  return s;
}

export function toFloat(value = 0) {
  return parseFloat(value).toFixed(2);
}

export const getScheduledDayDisplay = (values) => {
  const { schedule, dueDay, startDate } = values;
  if (schedule === RecurrenceType.WEEKLY) {
    return DayOfWeek[dueDay]?.display;
  } else if (schedule === RecurrenceType.OneTime) {
    return 'Daily';
  } else if (schedule !== RecurrenceType.DAILY) {
    return `${dayjs(startDate).format('Do')} of every ${RecurrenceDayjsKeys[schedule]}`;
  }
  return null;
};
export const parseTemplateString = (str = '') => {
  if (!str) return '';
  const re = /\#(.*?)\#/gm;
  const subst = '<span style="color:#1f75fe">{$1}</span>';
  const result = str?.replace(re, subst);
  return parse(result);
};
export const checkEqual = (a, b) => a.toLowerCase() === b.toLowerCase();

export function getEmis(paymentPlan) {
  let repeatFor = 0;
  let remainingEMI = 0;
  if (paymentPlan?.recurrence === 2) {
    repeatFor = dayjs(paymentPlan?.untilDate).diff(dayjs(paymentPlan?.startDate), 'week') + 1;
    remainingEMI = dayjs(paymentPlan?.untilDate).diff(dayjs(), 'week') + 1;
  } else if (paymentPlan?.recurrence === 6) {
    repeatFor = dayjs(paymentPlan?.untilDate).diff(dayjs(paymentPlan?.startDate), 'quarter') + 1;
    remainingEMI = dayjs(paymentPlan?.untilDate).diff(dayjs(), 'quarter') + 1;
  } else if (paymentPlan?.recurrence === 7) {
    repeatFor = dayjs(paymentPlan?.untilDate).diff(dayjs(paymentPlan?.startDate), 'month') + 1;
    remainingEMI = dayjs(paymentPlan?.untilDate).diff(dayjs(), 'month') + 1;
  } else if (paymentPlan?.recurrence === 8) {
    repeatFor = dayjs(paymentPlan?.untilDate).diff(dayjs(paymentPlan?.startDate), 'year') + 1;
    remainingEMI = dayjs(paymentPlan?.untilDate).diff(dayjs(), 'year') + 1;
  } else if (paymentPlan?.recurrence === 4) {
    repeatFor = dayjs(paymentPlan?.untilDate).diff(dayjs(paymentPlan?.startDate), 'month') + 1;
    remainingEMI = dayjs(paymentPlan?.untilDate).diff(dayjs(), 'month') + 1;
  }
  return { remainingEMI, repeatFor };
}


export function calculateSimpleInterest(tenure,rate,principal,recurrence){

  if(recurrence === 4 ){
    let amount = (principal * rate * tenure) / (100*12)
    return amount
  } else if(recurrence === 2) {
    let amount = (principal * rate * tenure) / (100*52)
    return amount
  } else if(recurrence === 1){
    let amount = (principal * rate * tenure) / (100*365)
    return amount
  }

}
export {
  groupBy,
  toLocalDate,
  toServerDate,
  toCapitalize,
  parseQueryParams,
  removeFieldFromUrlParam,
  setObjectToUrlParam,
  getBase64,
  buildUrl,
  deepen,
};
