const TxnType = {
  CREDIT: 0,
  DEBIT: 1,
};
export const DATE_FORMAT = 'DD-MMM-YYYY';
const persistentStorageKeys = ['tours', 'i18nextLng'];
const ENTITY = {
  CUSTOMER: 'customer',
  BILL: 'bill',
  TRANSACTION: 'transaction',
  INVOICE: 'invoice',
  ITEM: 'item',
};
const TxnDeliveryReceipt = {
  SEND: 1,
  NOT_SEND: 0,
};

const TxnStatus = {
  ACTIVE: 1,
  DELETED: 0,
};

const CUSTOMER_VIEW = {
  ADD_CUSTOMER: 'ADD_SINGLE',
  ADD_BULK_CUSTOMER: 'ADD_BULK',
};

const staffRoles = {
  Staff: 3,
  Owner: 1,
  Manager: 2,
};

const CUST_CONTEXT_MENU = {
  ADD_TAG: 'ADD_TAG',
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  SEND_REMINDER: 'SEND_REMINDER',
};

const QUICK_MENU = {
  MANAGE_TXN: 'menu_add_txn',
  SEARCH: 'menu_search',
  FILES: 'menu_files',
  REMINDER: 'menu_reminder',
  RECURRING: 'menu_recurring',
  PINNED: 'menu_pinned',
  NOTES: 'menu_notes',
  ACTIVITIES: 'menu_Activities',
};

const customerFilterList = [
  { id: 'all', title: 'All' },
  { id: 'today', title: 'Today' },
  { id: 'overdue', title: 'Overdue' },
  { id: 'upcoming', title: 'Upcoming' },
];

const customerFields = [
  { label: 'Name', key: 'name' },
  { label: 'Phone Number', key: 'mobile' },
  { label: 'Email', key: 'email' },
  { label: 'Balance', key: 'balance' },
  { label: 'Last Payment Date', key: 'lastActionDate' },
  { label: 'Due Date', key: 'dueDate' },
  { label: 'Due Status', key: 'dueStatus' },
  { label: 'Tags', key: 'tags' },
  { label: 'Group', key: 'groupId' },
  { label: 'Country', key: 'country' },
  { label: 'CallingCode', key: 'callingCode' },
  { label: 'Address', key: 'address.displayAddress' },
];
const RecurrenceType = {
  OneTime: 0,
  DAILY: 1,
  WEEKLY: 2,
  MONTHLY: 4,
  BIMONTHLY: 5,
  Quarterly: 6,
  HalfYearly: 7,
  Yearly: 8,
};
const RecurrenceDayjsKeys = {
  0: 'One Time',
  1: 'day',
  2: 'week',
  4: 'month',
  6: 'quarter',
  7: 'half yearly ',
  8: 'year',
};

export const RecurringStatus = {
  4: 'CANCELLED',
  1: 'EXPIRED',
};
export const PaymentCycle = {
  1: {
    value: 1,
    display: 'Daily',
  },
  2: {
    value: 2,
    display: 'Weekly',
  },
  4: {
    value: 4,
    display: 'Monthly',
  },
  6: {
    value: 6,
    display: 'Quarterly',
  },
  7: {
    value: 7,
    display: 'Half Yearly',
  },
  8: {
    value: 8,
    display: 'Yearly',
  },
};

export const DayOfWeek = {
  0: {
    value: 0,
    display: 'Sunday',
  },
  1: {
    value: 1,
    display: 'Monday',
  },
  2: {
    value: 2,
    display: 'Tuesday',
  },
  3: {
    value: 3,
    display: 'Wednesday',
  },
  4: {
    value: 4,
    display: 'Thursday',
  },
  5: {
    value: 5,
    display: 'Friday',
  },
  6: {
    value: 6,
    display: 'Saturday',
  },
};

const transactionFields = [
  { label: 'Customer', key: 'customerId' },
  { label: 'Amount', key: 'amount' },
  { label: 'Type', key: 'type' },
  { label: 'Transaction Date', key: 'txnDate' },
  { label: 'Note/Comment', key: 'remark' },
  { label: 'Notify Customer', key: 'sms' },
];

export const FieLdTypes = {
  DROPDOWN: 'dropdown',
  TEXT: 'text',
};

export const ViewTypes = {
  TABLE: 'TABLE',
  LIST: 'LIST',
};

export const TagColors = [
  '#00796B',
  '#C2185B',
  '#7B1FA2',
  '#512DA8',
  '#303F9F',
  '#1976D2',
  '#0097A7',
  '#CB3000',
  '#388E3C',
  '#5D4037',
  '#455A64',
  '#0288D1',
  '#E59000',
  '#AFB42B',
  '#616161',
  '#689F38',
  '#FF6A6A',
  '#4FB5AA',
  '#9F5630',
  '#EA2F2F',
  '#27353C',
];

export const INVOICE_STATUS = {
  DRAFT: 'Draft',
  DELETED: 'Deleted',
  CANCELLED: 'Cancelled',
  PAID: 'Paid',
  UNPAID: 'UnPaid',
};

export const getBgColorFromStatus = (status) => {
  switch (status) {
    case INVOICE_STATUS.DRAFT:
      return 'orange';
    case INVOICE_STATUS.DELETED:
      return 'red';
    case INVOICE_STATUS.CANCELLED:
      return 'red';
    case INVOICE_STATUS.PAID:
      return 'green';
    case INVOICE_STATUS.UNPAID:
      return 'orange';

    default:
      return '#fff7df';
  }
};

const RecurringEntities = [
  {
    id: 'Bill',
    value: 'Recurring Bill',
    recurringType: 1,
  },
  {
    id: 'Invoice',
    value: 'Recurring Invoice',
    recurringType: 2,
  },
  {
    id: 'Transaction',
    value: 'Recurring Transaction',
    recurringType: 0,
  },
  {
    id: 'EMI',
    value: 'Recurring EMI',
    recurringType: 3,
  },
];

const countries = [
  'in',
  'us',
  'au',
  'np',
  'sg',
  'bd',
  'bw',
  'ca',
  'cn',
  'do',
  'eg',
  'gh',
  'gt',
  'hk',
  'il',
  'jp',
  'jo',
  'ke',
  'kr',
  'my',
  'mt',
  'mx',
  'nz',
  'ni',
  'ng',
  'pk',
  'pa',
  'ph',
  'lk',
  'tw',
  'tz',
  'th',
  'ug',
  'zw',
];

const countryOptions = ['India', 'United States', 'Australia', 'Nepal', 'Singapore'];

const itemType = { Fixed: 1, Variable: 2 };

export {
  TxnDeliveryReceipt,
  TxnStatus,
  TxnType,
  CUSTOMER_VIEW,
  CUST_CONTEXT_MENU,
  QUICK_MENU,
  staffRoles,
  customerFilterList,
  customerFields,
  ENTITY,
  persistentStorageKeys,
  transactionFields,
  RecurrenceType,
  RecurrenceDayjsKeys,
  RecurringEntities,
  countries,
  itemType,
  countryOptions,
};
