import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './ImportMapper.scss';
import { deepen } from '../../../utils/helper';
import { useMapper } from './useMapper';
const SelectField = memo((props) => {
  const { id, options, required, value, selectedValues, onChange } = props;
  return (
    <select
      id={id}
      key={id}
      value={value ?? ''}
      onChange={({ target }) => {
        let _systemFields = options.find((f) => f.label === target.value);
        if (!!_systemFields) {
          onChange(_systemFields);
        } else {
          onChange({
            label: target.value,
            key: target.value,
          });
        }
      }}
      required={required}
    >
      <option value="">Select</option>
      <option value={'DNI'}>Don't Import</option>
      {props.options?.map((op, idx) => (
        <option
          disabled={selectedValues.indexOf(op.key) > -1}
          key={`SelectField_option_${idx}`}
          value={op.label}
        >
          {op.label}
        </option>
      ))}
    </select>
  );
});
const ImportMapper = (props) => {
  const {
    data,
    systemFields,
    fileFields,
    className,
    options,
    tableStyles,
    tableClass,
    onFieldChange,
    footer,
    onFieldSubmit,
    isTransaction,
  } = props;
  const [fields, setFields] = useState([]);
  const { getTagsByString, getGroupByString } = useMapper();
  const rowError = [];
  useEffect(() => {
    console.log('ImportMapper loaded', fields, systemFields);
    const _fields = fileFields.map((field) => {
      const systemField = systemFields.find((f) => f.label === field);
      return {
        name: field,
        value: systemField?.label ?? null,
        key: systemField?.key ?? null,
        data: systemField?.data ?? null,
        customField: systemField?.customField ?? false,
      };
    });
    setFields(_fields);
  }, []);
  const onChangeHandler = (field, selected) => {
    const _fields = [...fields];
    const fieldIndex = _fields.findIndex((f) => f.name === field.name);
    if (fieldIndex >= 0) {
      _fields[fieldIndex] = {
        ..._fields[fieldIndex],
        data: selected.data,
        customField: selected.customField ?? false,
        value: selected.label,
        key: selected.customField ? `fieldvalues.${selected.key}` : selected.key,
      };
      setFields(_fields);
      onFieldChange(_fields[fieldIndex], _fields);
    }
  };

  const handleSubmit = () => {
    const replacementObj = fields.reduce((a, b) => ({ ...a, [b['name']]: b }), {});

    const newHeaders = fields
      .filter((f) => f.key !== 'DNI')
      .map((a) => ({ label: a.value, key: a.key }));

    const newData = data.map((oldObj, idx) => {
      let oldKeys = Object.keys(oldObj).filter(
        (k) => replacementObj[k] && replacementObj[k].key !== 'DNI'
      );

      if (isTransaction) {
        oldKeys.push('customerId');
      }

      let replacedItems = oldKeys
        .map((key) => {
          const newKey = replacementObj[key]?.key || key;

          // for account map tags
          if (newKey === 'tags') {
            const { comment, tags } = getTagsByString(oldObj[key]);
            if (comment) {
              rowError.push({
                line: Number(idx) + 1,
                field: key,
                error: comment,
              });
            }
            return { [newKey]: tags };
          }
          // for account map group
          if (newKey === 'groupId') {
            const { comment, group } = getGroupByString(oldObj[key]);
            if (comment) {
              rowError.push({
                line: Number(idx) + 1,
                field: key,
                error: comment,
              });
            }
            return { [newKey]: group };
          }
          return { [newKey]: oldObj[key] };
        })
        .reduce((a, b) => ({ ...a, ...b }));
      replacedItems.key = idx;
      return deepen(replacedItems);
    });
    console.log('newData', newData);
    onFieldSubmit && onFieldSubmit(fields, newData, newHeaders, rowError);
  };

  //memoizing
  // const onChangeHandler = useCallback(updateField, []);

  console.log('ImportMapper', { fileFields, systemFields, fields, rowError });
  return (
    <div className={'react-import-mapper ' + className}>
      <h3>{options?.title ?? 'Assign field labels'}</h3>
      <p>
        {options?.description ??
          'Select the Customer Profile field label from the column on right that\n' +
            '        best matches the imported field label on the left. One of the following\n' +
            '        must be set to begin an import: name, email, phone number. Any custom\n' +
            '        field must have a unique name.'}
      </p>
      <p className="text-muted">* All field labels assignment is Mandatory</p>
      <form onSubmit={handleSubmit}>
        <table className={tableClass} style={tableStyles}>
          <thead>
            <tr>
              <th>File Fields</th>
              <th>System file</th>
            </tr>
          </thead>
          <tbody>
            {fields.map((field, idx) => (
              <tr key={`table_tr_${idx}`} className={!!field.value ? '' : 'invalid-field'}>
                <td>
                  <b>{field.name}</b>
                </td>
                <td>
                  <SelectField
                    id={`select_${idx}`}
                    onChange={(selected) => onChangeHandler(field, selected)}
                    value={field.value}
                    selectedValues={fields.map((f) => f.key).filter((e) => e)}
                    options={systemFields}
                    required
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div>{footer}</div>
      </form>
    </div>
  );
};

ImportMapper.propTypes = {
  data: PropTypes.array,
  systemFields: PropTypes.array,
  fileFields: PropTypes.array,
  className: PropTypes.string,
  options: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    importBtnClass: PropTypes.string,
    tableClass: PropTypes.string,
    tableStyles: PropTypes.object,
  }),
};

export default memo(ImportMapper);
