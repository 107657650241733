import React from 'react';
import Text from 'antd/lib/typography/Text';
import { useTranslation } from 'react-i18next';

const Splash = () => {
  const { t } = useTranslation();
  return (
    <div id="preloader">
      <div className="loader" id="loader-1">
        <img className="img-fluid" width={'40px'} src={'/logo192.png'} />
      </div>
      <div className="brand-title">
        <h2>
          Meekhata &nbsp;
          <Text type={'primary'} className={'text-medium'}>
            Beta
          </Text>
        </h2>
        <p className="text-muted">{t('tag_line')}</p>
      </div>
    </div>
  );
};

export default Splash;
