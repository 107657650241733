import rootSaga from './states/rootSagas';
import rootReducer from './states/rootReducer';
import createSagaMiddleware from 'redux-saga';
import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {
  persistReducer,
  persistStore,
  REGISTER,
  REHYDRATE,
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
} from 'redux-persist';

const initStore = () => {
  /** Saga Middleware */
  const sagaMiddleware = createSagaMiddleware();

  /** Create middlewares for redux */
  // let middlewares = applyMiddleware(sagaMiddleware);

  /** Create redux store */
  // const store = createStore(rootReducer, compose(middlewares));
  /** redux toolkit */
  const persistConfig = {
    key: '@meekhata:root',
    storage,
    whitelist: ['account', 'business', 'customer', 'premiumPlans', 'systemConfig'],
    blacklist: ['customerFilters'],
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware({
        serializableCheck: {
          // https://github.com/rt2zz/redux-persist/issues/988
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
      sagaMiddleware,
    ],
    devTools: process.env.NODE_ENV !== 'production',
  });

  const persistor = persistStore(store);
  /** run saga watchers */
  sagaMiddleware.run(rootSaga);

  return { store, persistor };
};
export default initStore;
