class AnalyticService {
  static GTAG_ID = process.env.REACT_APP_GTAG_ID;
  static tagManagerArgs = {
    gtmId: this.GTAG_ID,
  };
  static user = {};
  /**
   * Initialize analytitcs
   */
  static init = () => {
    console.log('Init Analytics');
    // TagManager.initialize(this.tagManagerArgs);
  };

  /**
   * Log event
   * @param eventName
   * @param params
   */
  static logEvent = (eventName, params) => {
    console.log(eventName, params);
  };

  static setUserData = (user) => {
    this.user = user;
    if (!!window.google_tag_manager) {
      this.updateUserProperties();
    } else {
      window.addEventListener('gtm_loaded', 
        // Google Tag Manager has been loaded
        this.updateUserProperties()
      );
    }
  };

  static  updateUserProperties = () => {
    try {
      // eslint-disable-next-line no-undef
      heap.identify(this.user.id);
      // eslint-disable-next-line no-undef
      heap.addUserProperties({
        Name: this.user.firstName,
        Mobile: `${this.user.callingCode}${this.user.mobile}`,
      });
      // eslint-disable-next-line no-undef
      heap.addEventProperties({ 'Logged In': 'true' });
    } catch (e) {}
  };

  static clearUserProperties = () => {
    // eslint-disable-next-line no-undef
    heap.clearEventProperties();
    // eslint-disable-next-line no-undef
    heap.resetIdentity();
  };
}

export default AnalyticService;
