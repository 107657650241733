import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { ViewTypes } from '../../constants/app.constant';

const initialState = {
  data: {
    txnTableView: ViewTypes.TABLE,
    customerColumns: [],
  },
};

const systemConfigSlice = createSlice({
  name: 'systemConfig',
  initialState,
  reducers: {
    setSystemConfig: (state, action) => {
      state.data = action.payload;
    },
    updateSystemConfig: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },
  },
});

/** Actions */
export const systemConfigActions = systemConfigSlice.actions;

/** Reducer */
export default systemConfigSlice.reducer;

/** Selectors */
