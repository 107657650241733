const extractError = (res) => {
  if (res?.errors) {
    return { message: res?.title };
  } else if (res?.data?.message && res?.data?.errorCode && res?.data?.errorType) {
    return res?.data;
  } else if (res?.headers['x-mk-error']) {
    return {
      message: res.headers['x-mk-error'],
      key: res.headers['x-mk-key'],
    };
  } else if (res?.data?.message) {
    return res?.data;
  }
  return false;
};

export const hasError = (res) => {
  if (res.status >= 300) {
    return true;
  }
  return false;
};
export default extractError;
