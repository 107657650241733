import { all } from 'redux-saga/effects';
import { watchBusiness } from './Business/sagas';
import { watchCustomer } from './Customer/sagas';
import { watchTransaction } from './Transaction/sagas';
import { watchStaffMember } from './Memeber/sagas';
import { watchSubscriptionPlans } from './Subscription/sagas';

export function* watchSaga() {
  console.log('Sagas middle layer started!................');
}

export default function* rootSaga() {
  yield all([
    watchSaga(),
    watchBusiness(),
    watchCustomer(),
    watchTransaction(),
    watchStaffMember(),
    watchSubscriptionPlans(),
  ]);
}
