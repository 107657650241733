import React from 'react';
import classNames from 'classnames';
import { Spinner } from 'react-bootstrap';
import Spin from 'antd/lib/spin';
import Alert from 'antd/lib/alert';

const ContainerLoader = (props) => {
  const containerHeight = window.innerHeight;
  const styles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
  return (
    <div
      className="animated fadeIn"
      style={{ height: containerHeight, ...styles }}
    >
      <Spin tip="Loading..." />
    </div>
  );
};

export default ContainerLoader;
