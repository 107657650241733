/**
 * current current Active business
 */
import types from './types';

const getCurrentBusiness = (payload) => ({
  type: types.GET_BUSINESS_REQUEST,
  payload: payload?.id,
});

const setBusinessDetails = (payload) => ({
  type: types.GET_BUSINESS_SUCCESS,
  payload: payload,
});

const getAllBusinesses = () => ({
  type: types.GET_ALL_BUSINESS_REQUEST,
});

const getBusinessTags = () => ({
  type: types.GET_TAGS_REQUEST,
});

const setGroups = (payload) => ({
  type: types.SET_GROUPS,
  payload,
});

const loadCustomFields = () => ({
  type: types.GET_CUSTOM_FIELDS_REQUEST,
});

export default {
  getCurrentBusiness,
  getAllBusinesses,
  getBusinessTags,
  loadCustomFields,
  setBusinessDetails,
  setGroups,
};
