const ApiConstant = {
  // ***************** Auth APis ****************************
  LOGIN_OTP_REQUEST: '/api/v1/Login/{loginType}',
  LOGIN_VERIFY_OTP: '/api/v1/Login/{loginType}/Auth',
  LOGIN_RESEND_OTP: '/api/v2/user/SendOtp',
  REGISTER: '/api/v2/user/Register',
  DEVICE: '/api/v2/user/devices',
  GOOGLE_LOGIN: '/api/v2/user/googleLogin',
  MOBILE_REGISTER: '/api/v1/Login/register',
  SEND_OTP: '/api/v1/Login/{loginType}/otp',
  LOGOUT: '/api/v3/user/Logout',

  // ***************** User Profile APis ****************************
  GET_ACCOUNT: '/api/v2/user/profile',
  UPDATE_ACCOUNT: '/api/v2/user/profile',
  UPDATE_DEVICE: '/api/v2/user/devices',

  // ***************** Business /api **************************
  GET_ALL_BUSINESSES: '/api/v2/business',
  GET_BUSINESS_PROFILE: '/api/v2/business/profile/{businessId}',
  ADD_BUSINESS: '/api/v2/business/add',
  UPDATE_BUSINESS: '/api/v3/business/update',
  DELETE_BUSINESS: '/api/v2/business/inactivate/{businessId}',

  // ***************** Tags api **************************
  GET_TAGS: '/api/v2/Business/{businessId}/tags',
  ADD_TAG: '/api/v3/business/tags',
  UPDATE_TAG: '/api/v3/business/tags/{tagId}',
  DELETE_TAG: '/api/v3/business/tags/{tagId}',

  // ***************** Groups api **************************
  GET_USER_GROUPS: '/api/v1/UserGroups',
  ADD_USER_GROUP: '/api/v1/UserGroups',
  UPDATE_USER_GROUP: '/api/v1/UserGroups/{id}',
  DELETE_USER_GROUP: '/api/v1/UserGroups/{id}',

  // ***************** Custom Fields api **************************
  GET_CUSTOM_FIELDS: '/api/v3/business/customfields',
  ADD_CUSTOM_FIELD: '/api/v3/business/customfields',
  UPDATE_CUSTOM_FIELD: '/api/v3/business/customfields/{fieldId}',
  DELETE_CUSTOM_FIELD: '/api/v3/business/customfields/{fieldId}',

  // **************** Business Customer APis *********************
  GET_ALL_CUSTOMERS: '/api/v2/business/customers',
  GET_ALL_CUSTOMERS_PAGINATION: '/api/v3/Customer?nextToken={nextToken}',
  GET_BUSINESS_BALANCE: '/api/v2/business/{businessId}/outstanding',
  ADD_CUSTOMERS: '/api/v2/business/customers',
  DELETE_CUSTOMER: '/api/v2/business/customers/{customerId}',
  DELETE_BUlK_CUSTOMER: '/api/v3/Customer/bulk',
  UPDATE_CUSTOMER: '/api/v3/customer/{customerId}',
  UPDATE_CUSTOMER_TAG: '/api/v3/Customer/{customerId}/tags',
  ADD_BULK_CUSTOMERS: '/api/v2/business/{businessId}/customers/bulk',
  SEND_REMINDER: '/api/v3/customer/{customerId}/remind/queue',

  // **************** File APis ******************
  GET_SIGN_URL: '/api/v2/fileservice/presign',
  GET_CUSTOMER_DOCS: '/api/v3/Customer/{customerId}/documents',

  // **************** Customer Txn APis ******************
  GET_CUSTOMER_TXNS: '/api/v2/business/txns/customer/{customerId}',
  ADD_CUSTOMER_TXN: '/api/v3/Customer/{customerId}/txns',
  ADD_BULK_TXN: '/api/v1/Transactions/import',
  UPDATE_CUSTOMER_TXN: '/api/v2/business/txns/notes/{txnId}',
  DELETE_CUSTOMER_TXN: '/api/v2/business/txns/{txnId}',
  DELETE_BULK_CUSTOMER_TXN: '/api/v1/Transactions/Bulk',
  ADD_WRITEOFF_TXN: '/api/v3/Customer/{customerId}/WriteOff',

  // **************** Staff Management APis ******************
  GET_STAFF: '/api/v3/business/staff',
  ADD_STAFF: '/api/v3/business/staff',
  UPDATE_STAFF: '/api/v3/business/staff',
  DELETE_STAFF: '/api/v3/business/staff/{employeeId}',

  // **************** Staff Permission Set APIs ****************
  GET_PERMISSION_SET: '/api/v3/business/permissions',
  ADD_PERMISSION_SET: 'api/v3/business/permissions',
  UPDATE_PERMISSION_SET: 'api/v3/business/permissions/{permissionId}',
  DELETE_PERMISSION_SET: 'api/v3/business/permissions/{id}',

  // **************** Recurring APis ******************
  PAYMENT_PLANS: 'api/v3/customer/{customerId}/paymentplans',
  UPDATE_PAYMENT_PLAN: 'api/v3/customer/{customerId}/paymentplans/{paymentPlanId}',

  // **************** Business Overdue Reminder APis ******************
  SCHEDULE_BUSINESS_REMINDER: 'api/v3/business/Schedule',
  SCHEDULE_ACCOUNT_REMINDER: 'api/v3/Customer/{customerId}/dueschedule',

  // **************** TEMPLATE APis ******************
  CUSTOMER_IMPORT_TEMPLATE: '/v1/template/customer',
  TRANSACTION_IMPORT_TEMPLATE: '/v1/template/transaction',

  // **************** Business Reports APis ******************
  GET_TRANSACTION_REPORT: '/api/v1/reports/data',
  EXPORT_TRANSACTIONS: '/v1/ReportService?format={format}',
  // **************** Mail Setting APis ******************
  GET_EMAIL_TEMPLATES: '/api/v1/MailSettings/templates',
  SET_MAIL_SETTINGS: '/api/v1/MailSettings',

  // **************** Mail Setting APis ******************
  GET_SMS_TEMPLATES: '/api/v3/business/sms/templates',
  SET_SMS_TEMPLATES: '/api/v3/business/SmsTemplate',

  CREATE_BILL: '/api/v1/Invoices',
  UPDATE_BILL: '/api/v1/Invoices/{id}',
  GET_TRANSACTION_INVOICE: '/api/v1/Transactions',
  GET_INVOICES: '/api/v1/Invoices',

  LINK_TRANSACTION: '/api/v1/Transactions/{id}/link',
  UNLINK_TRANSCATION: '/api/v1/Transactions/{id}/unlink?linkId={linkId}&entity={entity}',

  GETALL_ITEMS: '/api/v3/Items',
  UPDATE_ITEMS: '/api/v3/Items/{id}',
  ADD_BULK_REMINDERS: '/api/v3/Customer/remind/queue',
  GETALL_TAX: '/api/v3/Tax',
  GET_DISCOUNTS: '/api/v3/Discounts',

  CANCEL_INVOICE: '/api/v1/Invoices/{id}/cancel',
  DELETE_INVOICE: '/api/v1/Invoices/{id}',
  DOWNLOAD_INVOICE: '/v1/Download',

  GET_PLANS: '/api/v1/PowerPlans',
  CANCEL_RECURRING:
    '/api/v3/Customer/{customerId}/paymentplans/{paymentPlanId}/cancel?deleteInvoices={deleteInvoices}&deleteTxns={deleteTxns}',

  PRE_PAYMENT: '/api/v3/Customer/{customerId}/paymentplans/{paymentPlanId}/pay',
  EMI_SCHEDULES: '/api/v3/Customer/{customerId}/paymentplans/{paymentPlanId}/schedule',

  GET_UNITS: '/api/v1/Lookups/units',
  GET_ALL_UNITS_TYPES: '/api/v1/UnitTypes',
  ADD_UNITS_TYPE: '/api/v1/UnitTypes',
  UPDATE_UNITS_TYPE: '/api/v1/UnitTypes/{id}',

  UPDATE_TAX: '/api/v3/Tax/{id}',
  UPDATE_DISCOUNT: '/api/v3/Discounts/{id}',

  GET_CATEGORY: '/api/v3/Category',
  UPDATE_CATEGORY: '/api/v3/Category/{id}',

  GET_MODIFIERS: '/api/v3/Modifiers',
  UPDATE_MODIFIERS: '/api/v3/Modifiers/{id}',

  INVOICE_SUMMARY: '/api/v1/Invoices/summary',
  GET_REMINDERS: '/api/v1/Reminders',
  PAYMENT_SUMMARY: '/api/v1/PaymentPlan/summary',

  GET_ALL_PAYMENTPLANS: '/api/v1/PaymentPlan',
  DELETE_TRANSACTION: '/api/v3/Customer/{customerId}/txns/{txnId}',

  GET_BUSINESS_TYPES: '/api/v1/Lookups/businesstypes',

  // **************** Subscription PREMIUM PLANS Apis *******************
  GET_PREMIUM_PLANS: '/api/v1/MeeSubscription/plans?version=1',
  GET_ADDONS: '/api/v1/MeeSubscription/addons',
  CREATE_PLAN_ORDER: '/api/v1/MeeRazorPay/order/plan',
  ADD_PLAN: '/api/v1/MeeRazorPay/plan',
  CREATE_ADDON_ORDER: '/api/v1/MeeRazorPay/order/addons',
  ADD_ADDON: '/api/v1/MeeRazorPay/addons',
  APPLY_DISCOUNT: '/api/v1/MeeSubscription/discount',
};

export default ApiConstant;
/*
@POST("api/v2/user/googleLogin")
Call<Authenticate> googleLogin(@Body SocialAuthRequest socialAuthRequest);

@POST("api/v2/user/Login")
Call<ResponseDTO<Authenticate>> userLogin(@Body UserLogin userLogin);

@POST("api/v2/user/Auth")
Call<ResponseDTO<Authenticate>> authExistingUser(@Body UserLogin userLogin);

@POST("api/v2/user/SendOtp")
Call<ResponseDTO<Authenticate>> sendOtp(@Body UserLogin userLogin);

@POST("api/v2/user/Register")
Call<ResponseDTO<Authenticate>> registerUser(@Body UserLogin userLogin);

@POST("api/v2/user/devices")
Call<ResponseBody> updateDevice(@Body Device device);

@POST("api/v2/user/trueCallerLogin")
Call<ResponseDTO<Authenticate>> saveTrueCallerDetails(@Body TrueCallerRequest trueProfile);

// *********** User Profile APis ****************************

@PUT("api/v2/user/profile")
Call<User> updateUserProfile(@Body User user);

@GET("api/v2/user/profile")
Call<User> getUserProfile();

@POST("api/v2/user/verifyMobile")
Call<Void> sendUserOTP(@Body MobileVerifyReq mobileVerification);

@POST("api/v2/user/setmobile")
Call<Void> verifyUserOTP(@Body MobileVerifyReq mobileVerification);

@GET("api/v2/user/MyBalance")
Call<ArrayList<ClientKhata>> getClientKhatas();

@GET("api/v2/user/Transactions/{businessId}/{accountId}")
Call<ArrayList<ClientKhataTxn>> getClientStoreTxns(@Path("businessId") String businessId, @Path("accountId") String accountId);

// *****************  Business api **************************

@GET("api/v2/business")
Call<ArrayList<BusinessProfile>> getBusinesses();

@GET("api/v2/business/profile/{businessId}")
Call<Business> getBusinessProfile(@Path("businessId") String businessId);

@POST("api/v2/business/add")
Call<BusinessProfile> createBusinessProfile(@Body BusinessProfile business);

@POST("api/v2/business/update")
Call<BusinessProfile> updateBusinessProfile(@Body BusinessProfile business);

@POST("api/v2/business/verifymobile")
Call<Void> sendOtp(@Body MobileVerifyReq mobileVerification);

@POST("api/v2/business/setmobile")
Call<Void> verifyOtp(@Body MobileVerifyReq mobileVerification);

@GET("api/v2/business/inactivate/{businessId}")
Call<Void> deleteBusiness(@Path("businessId") String businessId);

// ***********Business Customer APis ****************************

@GET("api/v2/business/customers")
Call<ArrayList<Customer>> getAllCustomers();

@POST("api/v2/business/customers")
Call<Customer> addCustomer(@Body Customer customer);

@POST("api/v2/business/{businessId}/customers/bulk")
Call<ResponseDTO<ArrayList<Customer>>> addCustomersInBulk(@Path("businessId") String businessId, @Body ArrayList<Customer> customers);

@PUT("api/v3/customer/{customerId}")
Call<ResponseDTO<Customer>> updateCustomer(@Path("customerId") String customerId, @Body Customer customer);

@DELETE("api/v2/business/customers/{customerId}")
Call<Void> deleteCustomer(@Path("customerId") String customerId);

@POST("api/v3/customer/{customerId}/contacts")
Call<ResponseDTO<Boolean>> updateOtherContacts(@Path("customerId") String customerId, @Body ArrayList<MultipleContactNumber> multipleContactNumbers);

// ***********Customer Txn APis ****************************

@GET("api/v2/business/txns/customer/{customerId}")
Call<ArrayList<CustomerTxn>> getCustomerTxns(@Path("customerId") String customerId);

@POST("api/v2/business/txns")
Call<CustomerTxn> addCustomerTxn(@Body CustomerTxn customerTxn);

@PUT("api/v2/business/txns/notes/{txnId}")
Call<Void> updateCustomerTxn(@Path("txnId") String txnId, @Body CustomerTxnUpdate customerTxnUpdate);

@PUT("api/v2/business/txns/{txnId}")
Call<CustomerTxn> deleteCustomerTxn(@Path("txnId") String txnId);

// ***********Payment reminder APis ****************************
@GET("api/v3/customer/{customerId}/remind")
Call<ResponseDTO<Boolean>> sendPaymentReminder(@Path("customerId") String customerId);

// ***********Business reminder APis ****************************
@POST("api/v2/business/Schedule/{businessId}")
Call<ResponseDTO<BusinessProfile>> scheduleBusinessReminder(@Path("businessId") String businessId, @Body BusinessReminder businessReminder);

// *************Account Due Reminder Apis ****************************
@POST("api/v3/customer/{customerId}/schedule")
Call<ResponseDTO<AccountsReminder>> scheduleAccountsReminder(@Path("customerId") String customerId, @Body AccountsReminder accountsReminder); //TODO delete this

@POST("api/v3/customer/{customerId}/dueschedule")
Call<ResponseDTO<AccountsReminder>> schedulePaymentReminder(@Path("customerId") String customerId, @Body AccountsReminder accountsReminder);

@PUT("api/v3/customer/{customerid}/smsreminder")
Call<ResponseDTO<Boolean>> TurnOnOffSmsRemindersForCustomer(@Path("customerid") String customerId, @Query("

tus") int status);

/!*Staff apis*!/

@GET("api/v2/business/staff")
Call<ArrayList<Staff>> getStaff(@Header("X-Business") String BusinessId);

@POST("api/v2/business/staff")
Call<Staff> addStaff(@Header("X-Business") String BusinessId, @Body Staff staff);

@PUT("api/v2/business/staff")
Call<Staff> updateStaff(@Header("X-Business") String BusinessId, @Body Staff staff);


@DELETE("api/v2/business/staff/{staffId}")
Call<Void> deleteStaff(@Header("X-Business") String BusinessId, @Path("staffId") String staffId);

/!*File manager*!/
@POST("api/v2/fileservice/presign")
Call<FileUpload> getPreSignedUrlFromServer(@Body PreSignUrlReqDTO uri);

@PUT
Call<ResponseBody> uploadFileOnS3(@Url String uri, @Header("contentType") String contentType, @Body RequestBody body);

/!*ReportsResponseDTO Api *!/
@POST
Call<ResponseBody> generateReport(@Header("Authorization") String token, @Url String uri, @Body ReportsRequestDTO reportsRequestDTO);

/!**
 * For displaying in-app reportsResponseDTO
 *
 * @param uri
 * @param reportsRequestDTO
 * @param responseToken
 * @return
 *!/
@POST
Call<ResponseDTO<ResponseDataDTO<ReportsResponseDTO>>> getReport(@Header("Authorization") String token, @Url String uri, @Body ReportsRequestDTO reportsRequestDTO, @Query("nextToken") long responseToken);

/!*SMS meter *!/
@POST("api/v2/business/smsusage")
Call<SmsUsageDTO> getSmsUsage(@Body SmsUsageDTO smsUsageDTO);

@GET("api/v2/business/reminders/{businessId}/{accountId}")
Call<ResponseDTO<SMSLog>> getSmsLog(@Path("businessId") String BusinessId, @Path("accountId") String AccountId, @Query("nextToken") long responseToken);

@GET("api/v1/lookups/businesstypes")
Call<ResponseDTO<ArrayList<String>>> getBusinessTypes();

@GET("api/v2/Business/{businessId}/customfields")
Call<ResponseDTO<ArrayList<CustomField>>> getCustomFields(@Path("businessId") String businessId);

@POST("api/v2/Business/{businessId}/customfields")
Call<ResponseDTO<CustomField>> createCustomField(@Path("businessId") String businessId, @Body CustomField customField);

@PUT("api/v2/Business/{businessId}/customfields/{fieldId}")
Call<ResponseDTO<CustomField>> updateCustomField(@Path("businessId") String businessId, @Path("fieldId") String fieldId, @Body CustomField customField);

@DELETE("api/v2/Business/{businessId}/customfields/{fieldId}")
Call<ResponseDTO<Boolean>> deleteCustomField(@Path("businessId") String businessId, @Path("fieldId") String fieldId);

@GET("api/v2/Business/{businessId}/tags")
Call<ResponseDTO<ArrayList<LabelTag>>> getLabelTags(@Path("businessId") String businessId);

@POST("api/v2/Business/{businessId}/tags")
Call<ResponseDTO<LabelTag>> createLabelTag(@Path("businessId") String businessId, @Body LabelTag labelTag);

@PUT("api/v2/Business/{businessId}/tags/{tagid}")
Call<ResponseDTO<LabelTag>> updateLabelTag(@Path("businessId") String businessId, @Path("tagid") String tagid, @Body LabelTag labelTag);

@DELETE("api/v2/Business/{businessId}/tags/{tagid}")
Call<ResponseDTO<Boolean>> deleteLabelTag(@Path("businessId") String businessId, @Path("tagid") String tagid);

@POST("api/v3/customer/{customerId}/tags")
Call<ResponseDTO<Boolean>> saveLabelForCustomer(@Path("customerId") String customerId, @Body ArrayList<LabelTag> labelTag);

@PUT("api/v2/business/sms/settings/{businessId}/SmsComments/{active}")
Call<ResponseDTO<Boolean>> sendCommentsInSms(@Path("businessId") String BusinessId, @Path("active") int active);

@PUT("api/v2/business/sms/settings/{businessId}/PhoneNumberInSms/{active}")
Call<Void> sendPhoneNoInSMS(@Path("businessId") String BusinessId, @Path("active") int active);

@GET("api/v3/customer/{customerId}/paymentplans")
Call<ResponseDTO<ArrayList<RecurringPlanDTO>>> getAllPaymentPlans(@Path("customerId") String CustomerId);

@GET("https://maps.googleapis.com/maps/api/place/textsearch/json")
Call<GeoLocationResponse> getSearchLocation(@Query("query") String query, @Query("key") String key);

@POST("api/v3/customer/{customerId}/paymentplans")
Call<ResponseDTO<RecurringPlanDTO>> createPaymentPlan(@Path("customerId") String CustomerId, @Body RecurringPlanDTO recurringPlanDTO);

@PUT("api/v3/customer/{customerId}/paymentplans/{paymentPlanId}")
Call<ResponseDTO<RecurringPlanDTO>> updatePaymentPlan(@Path("customerId") String CustomerId, @Path("paymentPlanId") String paymentPlanId, @Body RecurringPlanDTO recurringPlanDTO);

@DELETE("api/v3/customer/{customerId}/paymentplans/{paymentPlanId}")
Call<ResponseDTO<Boolean>> deletePaymentPlan(@Path("customerId") String CustomerId, @Path("paymentPlanId") String paymentPlanId);

@PUT("api/v2/business/{businessId}/customerTitle")
Call<ResponseDTO<Boolean>> updateCustomerTitle(@Path("businessId") String businessId, @Body CustomerTitleRequest reportsRequestDTO);

@PUT("api/v2/Business/{businessId}/remindersettings")
Call<ResponseDTO<Boolean>> updateReminderSettings(@Path("businessId") String businessId, @Body ReminderSettings reminderSettings);
*/
