import { useCurrentBusiness } from '../../../hooks';
import { useSelector } from 'react-redux';
import { isArray, isEmpty } from 'lodash';
const findTag = (tags, tagData) => {
  let invalidTags = [];
  const result = tagData.map((tg) => {
    const tag = tags?.find((t) => t.text.toLowerCase() === tg.toLowerCase());
    if (!!tag) {
      return tag;
    } else {
      invalidTags.push(tg);
    }
    return undefined;
  });
  return {
    tags: result?.filter(Boolean),
    comment: !isEmpty(invalidTags) ? `${invalidTags.toString()} tags not found` : null,
  };
};
export function useMapper() {
  const { tags, groups } = useSelector(({ business }) => business);
  const getTagsByString = (tagString) => {
    if (!tagString) return {};
    let comment = null;
    const tagNameArray = tagString?.split(',');
    if (!isArray(tagNameArray)) {
      comment = 'Invalid tags, tags should be comma separated';
      return { comment, tags: null };
    } else {
      return findTag(tags, tagNameArray);
    }
  };

  const getGroupByString = (groupString) => {
    if (!groupString) return {};
    const group = groups?.find((g) => g.name?.toLowerCase() === groupString.toLowerCase());
    if (!!group) {
      return {
        group: group?.id,
        comment: null,
      };
    } else {
      return {
        group: null,
        comment: `${groupString} group not found` || null,
      };
    }
  };
  return { getTagsByString, getGroupByString };
}
