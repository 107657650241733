import { createSelector } from 'reselect';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { indexOf, orderBy, sumBy, isEmpty } from 'lodash';

dayjs.extend(relativeTime);

const getCustomers = (state) => state.list;
const getFilters = (state) => state.customerFilters;

export const getFilteredCustomers = createSelector(
  [getFilters, getCustomers],
  (filters, customers) => {
    if (!customers || customers?.length <= 0) return [];
    switch (filters.id) {
      case 'all':
        return orderBy(
          customers,
          (cust) => {
            return dayjs(cust.lastActionDate);
          },
          ['desc']
        );
      case 'today':
        return customers.filter(
          (c) =>
            dayjs(c.overDueDate).isSame(dayjs(), 'day') > 0 ||
            dayjs(c.dueDate).isSame(dayjs(), 'day') > 0
        );
      case 'overdue':
        return customers.filter(
          (c) =>
            dayjs(c.overDueDate).isBefore(dayjs(), 'day') > 0 ||
            dayjs(c.dueDate).isBefore(dayjs(), 'day') > 0 || c.balance < 0
        );
      case 'upcoming':
        return customers.filter(
          (c) =>
            dayjs(c.overDueDate).isAfter(dayjs(), 'day') > 0 ||
            dayjs(c.dueDate).isAfter(dayjs(), 'day') > 0
        );
      case 'tags':
        if (filters.value?.length <= 0) {
          return customers;
        }
        return customers.filter(
          (c) => c.tags?.findIndex(({ id }) => filters.value?.indexOf(id) > -1) > -1
        );
      case 'hidezero':
        return customers.filter((c) => c.balance != 0);
      case 'search':
        return customers.filter(({ mobile, name }) => {
          return (
            mobile?.match(new RegExp(filters.value, 'i')) ||
            name?.match(new RegExp(filters.value, 'i'))
          );
        });

      default:
        return customers;
    }
  }
);

export const getFilteredBalance = createSelector(
  [getFilters, getCustomers],
  (filters, customers) => {
    const _customers = getFilteredCustomers({
      list: customers,
      customerFilters: { id: filters.id },
    });
    const cashInBalance =
      sumBy(
        _customers.filter((c) => c.balance > 0),
        'balance'
      ) ?? 0;
    const cashOutBalance =
      sumBy(
        _customers.filter((c) => c.balance < 0),
        'balance'
      ) ?? 0;
      const upComingDues =  sumBy( _customers.filter(
        (c) =>
          dayjs(c.overDueDate).isAfter(dayjs(), 'day') > 0 ||
          dayjs(c.dueDate).isAfter(dayjs(), 'day') > 0
      ), 'balance') ?? 0;
    return {
      cashInBalance,
      cashOutBalance,
      upComingDues
    };
  }
);
/**
 * Get List of customer by current applied filter
 * @param state
 * @return {[{id: string, title: string}, {id: string, title: string}, {id: string, title: string}, {id: string, title: string}]}
 */
const getList = (state) => state.filterList;
export const getCustFilterValues = createSelector(
  [getList, getCustomers],
  (filtersList, customers) => {
    let result = {
      tabData: [],
      totalCount: customers.length ?? 0,
      totalCashIn: 0,
      totalCashOut: 0,
    };
    result.tabData = filtersList?.map((f) => {
      const _customers = getFilteredCustomers({
        list: customers,
        customerFilters: { id: f.id },
      })?.filter((c) => c.balance < 0);
      return {
        ...f,
        amount: sumBy(_customers, 'balance'),
        count: _customers?.length ?? 0,
      };
    });
    result.totalCashIn =
      sumBy(
        customers.filter((c) => c.balance > 0),
        'balance'
      ) ?? 0;
    result.totalCashOut =
      sumBy(
        customers.filter((c) => c.balance < 0),
        'balance'
      ) ?? 0;

    return result;
  }
);

/**
 * Get list of customers from Ids
 * @param state
 * @return {Validator<NonNullable<any[]>>}
 */
const getIds = (state) => state.customerIds;
export const getCustomersByIds = createSelector(
  [getIds, getCustomers],
  (customerIds = [], customers) => {
    return customers.filter(({ id }) => indexOf(customerIds, id) >= 0);
  }
);

const getCustomerId = (state) => state.customerId;
export const getCustomerById = createSelector([getCustomers, getCustomerId], (customers, id) => {
  const cust = getCustomersByIds({ customerIds: [id], list: customers });
  return !isEmpty(cust) ? cust[0] : {};
});
