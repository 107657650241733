import React, { Component } from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import ImgWarning from '../../../assets/img/under_construction.svg';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <React.Fragment style={styles.container}>
          <Container>
            <Row className="text-center ">
              <Col md="12" className="align-items-center">
                <div className="p-3">
                  <Image src={ImgWarning} width="30%" />
                </div>
              </Col>
              <Col md="12">
                <h2 className="pt-3 mb-3">Something went wrong</h2>
                <p className="text-muted">
                  The page having some technical hiccups. We know about the
                  problem and we're working to get this things back to normal
                  quickly.
                </p>
              </Col>
              <Col md="12">
                <Button onClick={window.location.reload} variant={'primary'}>
                  Restart
                </Button>
              </Col>
            </Row>
          </Container>
        </React.Fragment>
      );
    }
    return this.props.children;
  }
}

const styles = {
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    height: '40%',
    alignSelf: 'center',
  },
  oopsText: {
    color: '#22c361',
    fontSize: '20rem',
    fontFamily: 'FiraSans-Bold',
    justifyContent: 'center',
    alignSelf: 'center',
    marginBottom: '60rem',
    marginHorizontal: '25rem',
  },
  restart: {
    backgroundColor: '#22c361',
    fontSize: '26rem',
    alignSelf: 'center',
    marginVertical: '40rem',
    marginTop: '50rem',
    borderRadius: 5,
    fontFamily: 'FiraSans-Bold',
    paddingVertical: 3,
    width: '40%',
  },
};

export default ErrorBoundary;
