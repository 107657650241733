const GET_MEMBER_REQUEST = 'member/GET_MEMBER_REQUEST';
const GET_MEMBER_IN_PROGRESS = 'member/GET_MEMBER_IN_PROGRESS';
const GET_MEMBER_SUCCESS = 'member/GET_MEMBER_SUCCESS';
const GET_MEMBER_FAILURE = 'member/GET_MEMBER_FAILURE';

const ADD_MEMBER_REQUEST = 'member/ADD_MEMBER_REQUEST';
const ADD_MEMBER_IN_PROGRESS = 'member/ADD_MEMBER_IN_PROGRESS';
const ADD_MEMBER_SUCCESS = 'member/ADD_MEMBER_SUCCESS';
const ADD_MEMBER_FAILURE = 'member/ADD_MEMBER_FAILURE';

const UPDATE_MEMBER_REQUEST = 'member/UPDATE_MEMBER_REQUEST';
const UPDATE_MEMBER_IN_PROGRESS = 'member/UPDATE_MEMBER_IN_PROGRESS';
const UPDATE_MEMBER_SUCCESS = 'member/UPDATE_MEMBER_SUCCESS';
const UPDATE_MEMBER_FAILURE = 'member/UPDATE_MEMBER_FAILURE';

const DELETE_MEMBER_REQUEST = 'member/DELETE_MEMBER_REQUEST';
const DELETE_MEMBER_IN_PROGRESS = 'member/DELETE_MEMBER_IN_PROGRESS';
const DELETE_MEMBER_SUCCESS = 'member/DELETE_MEMBER_SUCCESS';
const DELETE_MEMBER_FAILURE = 'member/DELETE_MEMBER_FAILURE';

export default {
  GET_MEMBER_REQUEST,
  GET_MEMBER_IN_PROGRESS,
  GET_MEMBER_SUCCESS,
  GET_MEMBER_FAILURE,

  ADD_MEMBER_REQUEST,
  ADD_MEMBER_IN_PROGRESS,
  ADD_MEMBER_SUCCESS,
  ADD_MEMBER_FAILURE,

  UPDATE_MEMBER_REQUEST,
  UPDATE_MEMBER_IN_PROGRESS,
  UPDATE_MEMBER_SUCCESS,
  UPDATE_MEMBER_FAILURE,

  DELETE_MEMBER_REQUEST,
  DELETE_MEMBER_IN_PROGRESS,
  DELETE_MEMBER_SUCCESS,
  DELETE_MEMBER_FAILURE,
};
