/**
 *  Constants used to GET business profile
 */

const GET_BUSINESS_REQUEST = 'business/GET_BUSINESS_REQUEST';
const GET_BUSINESS_SUCCESS = 'business/GET_BUSINESS_SUCCESS';
const GET_BUSINESS_FAILURE = 'business/GET_BUSINESS_FAILURE';
const GET_BUSINESS_IN_PROGRESS = 'business/GET_BUSINESS_IN_PROGRESS';

/**
 *  Constants used to Create business profile
 */

const CREATE_BUSINESS_REQUEST = 'business/CREATE_BUSINESS_REQUEST';
const CREATE_BUSINESS_SUCCESS = 'business/CREATE_BUSINESS_SUCCESS';
const CREATE_BUSINESS_FAILURE = 'business/CREATE_BUSINESS_FAILURE';
const CREATE_BUSINESS_IN_PROGRESS = 'business/CREATE_BUSINESS_IN_PROGRESS';

/**
 *  Constants used to Update business profile
 */

const UPDATE_BUSINESS_REQUEST = 'business/UPDATE_BUSINESS_REQUEST';
const UPDATE_BUSINESS_SUCCESS = 'business/UPDATE_BUSINESS_SUCCESS';
const UPDATE_BUSINESS_FAILURE = 'business/UPDATE_BUSINESS_FAILURE';
const UPDATE_BUSINESS_IN_PROGRESS = 'business/UPDATE_BUSINESS_IN_PROGRESS';

/**
 *  Constants used to Update business profile
 */

const DELETE_BUSINESS_REQUEST = 'business/DELETE_BUSINESS_REQUEST';
const DELETE_BUSINESS_SUCCESS = 'business/DELETE_BUSINESS_SUCCESS';
const DELETE_BUSINESS_FAILURE = 'business/DELETE_BUSINESS_FAILURE';
const DELETE_BUSINESS_IN_PROGRESS = 'business/DELETE_BUSINESS_IN_PROGRESS';

/**
 *  Constants used to GET all business for
 *  current loggedIN
 */

const GET_ALL_BUSINESS_REQUEST = 'business/GET_ALL_BUSINESS_REQUEST';
const GET_ALL_BUSINESS_SUCCESS = 'business/GET_ALL_BUSINESS_SUCCESS';
const GET_ALL_BUSINESS_FAILURE = 'business/GET_ALL_BUSINESS_FAILURE';
const GET_ALL_BUSINESS_IN_PROGRESS = 'business/GET_ALL_BUSINESS_IN_PROGRESS';

const SET_GROUPS = 'SET_GROUPS';

const GET_TAGS_REQUEST = 'GET_TAGS_REQUEST';
const GET_TAGS_IN_PROGRESS = 'GET_TAGS_IN_PROGRESS';
const GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS';
const GET_TAGS_FAILURE = 'GET_TAGS_FAILURE';

const GET_CUSTOM_FIELDS_REQUEST = 'business/GET_CUSTOM_FIELDS_REQUEST';
const GET_CUSTOM_FIELDS_SUCCESS = 'business/GET_CUSTOM_FIELDS_SUCCESS';

export default {
  GET_BUSINESS_REQUEST,
  GET_BUSINESS_IN_PROGRESS,
  GET_BUSINESS_SUCCESS,
  GET_BUSINESS_FAILURE,

  GET_ALL_BUSINESS_REQUEST,
  GET_ALL_BUSINESS_IN_PROGRESS,
  GET_ALL_BUSINESS_SUCCESS,
  GET_ALL_BUSINESS_FAILURE,

  CREATE_BUSINESS_REQUEST,
  CREATE_BUSINESS_IN_PROGRESS,
  CREATE_BUSINESS_SUCCESS,
  CREATE_BUSINESS_FAILURE,

  UPDATE_BUSINESS_REQUEST,
  UPDATE_BUSINESS_IN_PROGRESS,
  UPDATE_BUSINESS_SUCCESS,
  UPDATE_BUSINESS_FAILURE,

  DELETE_BUSINESS_REQUEST,
  DELETE_BUSINESS_IN_PROGRESS,
  DELETE_BUSINESS_SUCCESS,
  DELETE_BUSINESS_FAILURE,

  GET_TAGS_REQUEST,
  SET_GROUPS,

  GET_TAGS_SUCCESS,
  GET_TAGS_FAILURE,
  GET_TAGS_IN_PROGRESS,

  GET_CUSTOM_FIELDS_REQUEST,
  GET_CUSTOM_FIELDS_SUCCESS,
};
