import React, { memo, useState } from 'react';
import { Avatar } from 'antd';
const ColorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae'];
const ProfileAvatar = (props) => {
  const { title, ...rest } = props;
  const color = ColorList[Math.floor(Math.random() * ColorList.length)];
  return (
    <Avatar
      style={{ backgroundColor: color, verticalAlign: 'middle' }}
      {...rest}
      className="text-uppercase border rounded-circle"
    >
      {rest.src ? '' : title?.match(/\b\w/g)?.join('').slice(0, 2)}
    </Avatar>
  );
};

ProfileAvatar.propTypes = {};

export default memo(ProfileAvatar);
