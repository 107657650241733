import Types from './types';

/**
 * Member GET
 * @param state
 * @param action
 * @return {{isLoading: boolean, profile: null, isAuthenticate: boolean, error: *, user: null}|{isLoading: boolean, profile: null, isAuthenticate: boolean, error: boolean, user: null}|{isLoading: boolean, profile: null, isAuthenticate: boolean, error: null, user: null}|{isLoading: boolean, profile: null, isAuthenticate: boolean, error: boolean, user: *}}
 */
const initialState = {
  members: null,
  isLoading: false,
  error: null,
  data: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_MEMBER_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case Types.GET_MEMBER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        members: action.payload,
        error: false,
      };
    case Types.GET_MEMBER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case Types.ADD_MEMBER_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case Types.ADD_MEMBER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        error: false,
      };
    case Types.ADD_MEMBER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case Types.UPDATE_MEMBER_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case Types.UPDATE_MEMBER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        error: false,
      };
    case Types.UPDATE_MEMBER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
