import { TxnType } from '../../constants/app.constant';
import types from './types';

const INITIAL_VALUE = {
  details: null,
  totalBalance: 0,
  loading: false,
  error: null,
  list: [],
  listLoading: true,
  listError: false,
  addUpdateTxnLoading: false,
  addUpdateTxnError: null,
  deleteTxnLoading: false,
};

const reducer = (state = INITIAL_VALUE, action) => {
  switch (action.type) {
    /**
     * GET All Customer Transactions
     */
    case types.GET_ALL_TXN_IN_PROGRESS:
      return {
        ...state,
        listLoading: true,
        listError: null,
      };
    case types.GET_ALL_TXN_SUCCESS:
      let balance = 0;
      let newTxns = action.payload
        ?.reverse()
        .map((txn) => {
          return {
            ...txn,
            closingBalance: (balance += txn.type === TxnType.DEBIT ? -1 * txn.amount : txn.amount),
          };
        })
        .reverse();

      return {
        ...state,
        totalBalance: balance,
        listLoading: false,
        list: newTxns,
        listError: null,
      };
    case types.GET_ALL_TXN_FAILURE:
      return {
        ...state,
        listLoading: false,
        listError: action.error,
      };

    /**
     * Add Customer Transactions
     */
    case types.CREATE_TXN_IN_PROGRESS:
      return {
        ...state,
        addUpdateTxnLoading: true,
        addUpdateTxnError: null,
      };
    case types.CREATE_TXN_SUCCESS:
      const txns = [...state.list];
      txns.unshift(action.payload);
      return {
        ...state,
        list: txns,
        addUpdateTxnLoading: false,
        addUpdateTxnError: null,
      };
      case types.DELETE_TXN_IN_PROGRESS:
      return {
        ...state,
        deleteTxnLoading: true,
        addUpdateTxnError: null,
      };
      case types.DELETE_TXN_SUCCESS:
        let txnss = [...state.list];
        const txnId = action.payload;
       
        return {
          ...state,
          list: txnss.filter((c) => c.id != txnId),
          deleteTxnLoading: false,
          addUpdateTxnError: null,
        };
        case types.DELETE_TXN_FAILURE:
      return {
        ...state,
        deleteTxnLoading: false,
        addUpdateTxnError: action.error,
      };
    case types.CREATE_TXN_FAILURE:
      return {
        ...state,
        addUpdateTxnLoading: false,
        addUpdateTxnError: action.error,
      };

    /**
     * Update Customer Transactions
     */
    case types.UPDATE_TXN_IN_PROGRESS:
      return {
        ...state,
        addUpdateTxnLoading: true,
        addUpdateTxnError: null,
      };
    case types.UPDATE_TXN_SUCCESS:
      // const newTxn = action.payload;
      // const txnsList = [...state.list];
      // const txnIndex = txnsList.findIndex(t => t.id = newTxn.id);
      // txnsList[txnIndex] = newTxn;
      return {
        ...state,
        addUpdateTxnLoading: false,
        addUpdateTxnError: null,
      };
    case types.UPDATE_TXN_FAILURE:
      return {
        ...state,
        addUpdateTxnLoading: false,
        addUpdateTxnError: action.error,
      };
      case types.CREATE_WRITEOFF_TXN_SUCCESS:
        const txns1 = [...state.list];
        txns.unshift(action.payload);
        return {
          ...state,
          list: txns1,
          addUpdateTxnLoading: false,
          addUpdateTxnError: null,
        };

    default:
      return state;
  }
};

export default reducer;
