import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { version } from '../../package.json';
export class BugsnagService {
  static instance;
  static bugsnagConfig = {
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: ['production'],
    releaseStage: process.env.REACT_APP_ENV,
    appVersion: version,
    appType: 'web_business',
  };
  constructor() {
    if (!BugsnagService.instance) {
      BugsnagService.instance = this;
    }

    return BugsnagService.instance;
  }

  static init() {
    Bugsnag.start(BugsnagService.bugsnagConfig);
  }

  static setUser(user = {}) {
    Bugsnag.setUser(user?.id, user?.email, user?.firstName);
  }
  static setMetadata(business) {
    Bugsnag.addMetadata('business', {
      name: business.name,
      country: 'in',
      email: business.email,
      id: business.id,
    });
  }

  static logError(error) {
    Bugsnag.notify(error);
  }
}
