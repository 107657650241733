/**
 * GET actions
 */
import types from './types';

/**
 * Action for GET
 * @return {{type: string}}
 */
const getStaffMembers = () => ({
  type: types.GET_MEMBER_REQUEST,
});

/**
 * add Staff Member
 * @param id
 * @param onSuccess
 * @param onFailed
 * @return {{payload: *, onFailed: *, type: string, onSuccess: *}}
 */
const addStaffMember = (data, onSuccess, onFailed) => ({
  type: types.ADD_MEMBER_REQUEST,
  payload: data,
  onSuccess: onSuccess,
  onFailed: onFailed,
});

/**
 * Update staff member
 * @param payload
 * @param onSuccess
 * @param onFailed
 * @return {{payload: *, onFailed: *, type: string, onSuccess: *}}
 */
const updateStaffMember = (payload, onSuccess, onFailed) => ({
  type: types.UPDATE_MEMBER_REQUEST,
  payload: payload,
  onSuccess: onSuccess,
  onFailed: onFailed,
});

/**
 * delete staff member
 * @param id
 * @param onSuccess
 * @param onFailed
 * @return {{payload: *, onFailed: *, type: string, onSuccess: *}}
 */
const deleteStaffMember = (id, onSuccess, onFailed) => ({
  type: types.DELETE_MEMBER_REQUEST,
  employeeId: id,
  onSuccess,
  onFailed,
});

export { getStaffMembers, updateStaffMember, addStaffMember, deleteStaffMember };
