import React from 'react';
import { Input } from 'antd';
import './AmountInput.scss';
const AmountInput = (props) => {
  return (
    <Input
      type={'number'}
      className="amountInput"
      placeholder={0}
      size={'large'}
      prefix={'₹'}
      {...props}
    />
  );
};

export default AmountInput;
